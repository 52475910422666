import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Button } from "primereact/button";
import DashboardMenu from "../DashboardMenu";
import axios from "axios";
import Moment from "moment";
import UserExperiencesTable from "../experience/UserExperiencesTable";
import UserPointsofinterestTable from "../pointofinterest/UserPointsofinterestTable";
import { useDeleteUserMutation, useGetUserNotificationTopicsQuery, useGetNotificationTopicsQuery } from "../../services/smartsenseApi";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function UserProfile() {
    let userData = getUserData();
    const [deleteUserRequest, deleteUserRequestResponse] = useDeleteUserMutation();
    const [unDeleteUserRequest, unDeleteUserRequestResponse] = useDeleteUserMutation();
    // const [deleteUserRequest, deleteUserRequestResponse] = useState(false);
    // const [unDeleteUserRequest, unDeleteUserRequestResponse] = useState(false);
    const [showDeleteComp, setShowDeleteComp] = useState(false);
    const [notificationTopics, setNotificationTopics] = useState([]);
    const [userNotificationTopics, setUserNotificationTopics] = useState([]);
    let navigate = useNavigate();

    const {
        data: notificationTopicsData,
        isFetching: isFetchingNotificationTopics,
        error: errorNotificationTopicsData,
    } = useGetNotificationTopicsQuery();
    const {
        data: userNotificationTopicsData,
        isFetching: isFetchingUserNotificationTopics,
        error: errorUserNotificationTopicsData,
    } = useGetUserNotificationTopicsQuery(userData.user.id);

    useEffect(() => {
        if (!isFetchingNotificationTopics) {
            if (errorNotificationTopicsData?.data?.success === false) navigate("/error/404");
            else if (notificationTopicsData?.success) {
                // console.log(notificationTopicsData.data);
                // console.log('^^here');
                setNotificationTopics(notificationTopicsData?.data);
            }
        }
    }, [isFetchingNotificationTopics]);

    useEffect(() => {
        if (!isFetchingUserNotificationTopics) {
            if (errorUserNotificationTopicsData?.data?.success === false) navigate("/error/404");
            else if (userNotificationTopicsData?.success) {
                setUserNotificationTopics(userNotificationTopicsData?.data);
            }
        }
    }, [isFetchingUserNotificationTopics]);

    Moment.locale("en");
    var dt1 = "2023-05-02T00:00:00";
    var dt2 = "2024-05-02T00:00:00";

    var today = new Date();
    var ndate = new Date(new Date().setDate(today.getDate() + 30));
    if (!userData.user.delete_account_at) userData.user.delete_account_at = today;
    var future = new Date(userData.user.delete_account_at);
    userData = {
        id: userData.user.id,
        delete_account_at: userData.user.delete_account_at,
        name: userData.user.name,
        email: userData.user.email,
        avatar: userData.user.avatar,
        isCompany: userData?.user?.roles.some((e) => e.code == "company") ? true : false,

        companyAddress: userData?.user?.company?.address ? userData?.user?.company?.address : "Nu există informația",
        companyName: userData?.user?.company?.name ? userData?.user?.company?.name : "Nu există informația",
        companyCui: userData?.user?.company?.cui ? userData?.user?.company?.cui : "Nu există informația",
        companyReg_com: userData?.user?.company?.reg_com ? userData?.user?.company?.reg_com : "Nu există informația",

        subscriptionType: userData.user.subscriptionType ? userData.user.subscriptionType : null,
        subscriptionFrom: Moment(userData.subscriptionFrom).format("d/M/y"),
        subscriptionTo: Moment(userData.subscriptionTo).format("d/M/y"),
        token: userData.token,
    };

    const deleteUser = (userId) => {
        userData = JSON.parse(localStorage.getItem("userData"));
        userData.user.delete_account_at = ndate;
        localStorage.setItem("userData", JSON.stringify(userData));

        deleteUserRequest(userId);
        setShowDeleteComp(false);
    };

    const unDeleteUser = (userId) => {
        userData = JSON.parse(localStorage.getItem("userData"));
        userData.user.delete_account_at = null;
        localStorage.setItem("userData", JSON.stringify(userData));

        unDeleteUserRequest(userId);
    };

    const checkContains = (obj, list) => {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].id === obj.id) {
                return true;
            }
        }
        return false;
    };

    const setChecked = (_notificationTopic, _userNotificationTopics) => {
        var _theTopics = [];
        if (checkContains(_notificationTopic, _userNotificationTopics))
            _theTopics = _userNotificationTopics.filter(function (notificationTopic) {
                return notificationTopic.id !== _notificationTopic.id;
            });
        else {
            _theTopics = [..._userNotificationTopics, _notificationTopic];
        }
        setUserNotificationTopics(_theTopics);

        axios({
            method: "put",
            url: process.env.REACT_APP_API_URL + "/web/notifications/update-subscription-topics",
            data: {
                user_id: userData.id,
                notification_topics_ids: _theTopics.map((a) => a.id),
            },
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then(
            (response) => {
                console.log(response);
            },
            (error) => {
                alert("Modificarea informatiilor a esuat!");

                console.log(error);
            }
        );
    };

    return (
        <>
            <div className="bg-[rgb(239,243,248)] min-h-full">
                {today >= future ? (
                    <>
                        {showDeleteComp && (
                            <>
                                <div
                                    id="defaultModal"
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
                                    className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
                                    <div
                                        className="w-full h-full max-w-2xl p-4 md:h-auto"
                                        style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
                                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Stergere cont</h3>
                                                <button
                                                    type="button"
                                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                                    data-modal-toggle="defaultModal">
                                                    <span className="sr-only">Inchide popup</span>
                                                </button>
                                            </div>
                                            <div className="p-6 space-y-6">
                                                <p className="text-base leading-relaxed dark:text-gray-400">
                                                    Sunteti sigur(a) ca doriti sa va stergeti contul?
                                                </p>
                                                <p className="text-base leading-relaxed dark:text-gray-400">
                                                    Timp de 30 de zile vei avea posibiliatea de a-ti reactiva contul fara a pierde
                                                    informatiile deja stocate
                                                </p>
                                            </div>
                                            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                                <button
                                                    onClick={() => deleteUser(userData.id)}
                                                    data-modal-toggle="defaultModal"
                                                    type="button"
                                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                    Sterge
                                                </button>
                                                <button
                                                    onClick={() => setShowDeleteComp(false)}
                                                    data-modal-toggle="defaultModal"
                                                    type="button"
                                                    className="bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                                    Anuleaza
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        <Header />
                        <div className="container box-border py-8 mx-auto my-10 bg-white rounded-lg newtheme">
                            <DashboardMenu />
                            <div className="col-md-9 col-sm-12">
                                <div className="dashboard-title fl-wrap">
                                    <h1 className="text-2xl text-[#566985]">Panou de comandă</h1>
                                </div>

                                <div className="profile-edit-container fl-wrap block_box">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="avatar_dashb_img_container">
                                                <div className="avatar_dashb_img">
                                                    <img src={userData.avatar}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="w-full bg-white rounded-lg">
                                                <h1 className="mb-2 text-xl text-[#566985]">Detaliile contului tau</h1>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <b>Nume</b>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p>{userData.name}</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-6">
                                                            <b>E-mail</b>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p>{userData.email}</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-6">
                                                            <b>Tip utilizator</b>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {userData.isCompany && (
                                                                <p>
                                                                    <span>Persoana juridica</span>
                                                                </p>
                                                            )}
                                                            {!userData.isCompany && (
                                                                <p>
                                                                    <span>Persoana fizica</span>
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {userData.isCompany && (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Denumire companie</b>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{userData.companyName}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Adresa</b>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{userData.companyAddress}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>CIF</b>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{userData.companyCui}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Registrul Comertului</b>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{userData.companyReg_com}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {userData.subscrrowonType && (
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <b>Tip cont</b>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{userData.subscriptionType}</p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* <div classNarowflex">
                                                        <div className="col-6">
                                                            <b>Subscriptie valida de la</b>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p>{userData.subscriptionFrom}</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-6">
                                                            <b>Subscriptie valida pana la</b>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p>{userData.subscriptionTo}</p>
                                                        </div>
                                                    </div> */}
                                                    {/* <a href="/user/profile/edit">Editeaza</a> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="w-full pb-16 bg-white rounded-lg">
                                                <h1 className="mb-2 text-xl text-[#566985]">Topic-uri notificari</h1>
                                                <div>
                                                    <p>Selecteaza din lista de mai jos tipurile de notificari de care esti interesat:</p>

                                                    {notificationTopics && (
                                                        <>
                                                            {notificationTopics.map((notificationTopic, index) => (
                                                                <div className="mb-4" key={index}>
                                                                    <Checkbox
                                                                        onChange={(e) =>
                                                                            setChecked(notificationTopic, userNotificationTopics)
                                                                        }
                                                                        checked={checkContains(notificationTopic, userNotificationTopics)}
                                                                        tooltip={notificationTopic.title}
                                                                        tooltipOptions={{ position: "top" }}></Checkbox>
                                                                    <label htmlFor="cb1" className="ml-1 p-checkbox-label">
                                                                        {notificationTopic.title}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {userData.isCompany &&
                                        <div className="pt-10 row">
                                            <div className="col-md-6">
                                                <h1 className="mb-2 text-xl text-[#566985]">Experientele tale</h1>
                                                {userData.isCompany ? (
                                                    <>
                                                        <UserExperiencesTable />
                                                    </>
                                                ) : (
                                                    <>
                                                        <div>Poti adauga experiente dupa ce iti schimbi tipul subscrierii</div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <h1 className="mb-2 text-xl text-[#566985]">Punctele tale de interes</h1>
                                                {userData.isCompany ? (
                                                    <>
                                                        <UserPointsofinterestTable />
                                                    </>
                                                ) : (
                                                    <>
                                                        <div>Poti adauga puncte de interes dupa ce iti schimbi tipul subscrierii</div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="profile-edit-container fl-wrap block_box">
                                    <div className="flex justify-center">
                                        <button className="p-button p-component p-button-raised p-button-secondary p-button-text p-button-sm">
                                            <a href="#" onClick={() => setShowDeleteComp(true)} style={{ color: "red", fontWeight: "600" }}>
                                                Șterge contul
                                            </a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
                ) : (
                    <>
                        <Header />
                        <div className="container box-border py-8 mx-auto bg-white rounded-lg newtheme">
                            <DashboardMenu />

                            <div className="col-md-9 col-sm-12">
                                <div className="py-4 space-y-6">
                                    <div className="dashboard-title fl-wrap">
                                        <h1 className="text-2xl text-[#566985] ">Bine ai revenit, {userData.name}!</h1>
                                    </div>
                                    <div className="space-y-5 profile-edit-container fl-wrap block_box text-[#666] font-medium">
                                        <div className="w-full">
                                            <div className="bg-white rounded-lg">
                                                <h1 className="text-lg text-center">
                                                    Contul tau este dezactivat. Pana la data de xxx iti poti reactiva contul fara a pierde
                                                    accesul la:
                                                </h1>

                                                <ul className="my-10 text-[#666] font-medium text-center">
                                                    <li>Experiente favorite</li>
                                                    <li>Puncte de interes favorite</li>
                                                    <li>Posibilitatea de a lasa revie-uri</li>
                                                    <li>Notificari cand se adauga noutati</li>
                                                    <li>Adaugarea de puncte de interes</li>
                                                    <li>Adaugarea de experiente</li>
                                                </ul>

                                                <Button
                                                    type="button"
                                                    fontSize="small"
                                                    onClick={() => unDeleteUser(userData.id)}
                                                    label="Reactivează acum"
                                                    className="newtheme-submit"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
                )}
            </div>
        </>
    );
}

export default UserProfile;
