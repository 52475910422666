import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/authentification/PrivateRoute";

import HomePage from "./components/Pages/HomePage";
import Error from "./components/Error";
import Register from "./components/authentification/Register";
import Login from "./components/authentification/Login";
import Logout from "./components/authentification/Logout";
import UserProfile from "./components/user/UserProfile";
import UserProfileEdit from "./components/user/UserProfileEdit";
import FavoriteUser from "./components/dashboard/FavoriteUser";
import MapPlaces from "./components/map/MapPlaces";

import PointOfInterestView from "./components/pointofinterest/PointOfInterestView";
import CreatePointofinterest from "./components/pointofinterest/CreatePointofinterest";
import EditPointofinterest from "./components/pointofinterest/EditPointofinterest";

import ExperienceView from "./components/experience/ExperienceView";
import CreateExperience from "./components/experience/CreateExperience";
import EditExperience from "./components/experience/EditExperience";

import UserPointsofinterest from "./components/pointofinterest/UserPointsofinterest";
import UserExperiences from "./components/experience/UserExperiences";
import UserNotifications from "./components/notification/UserNotifications";

import Sensors from "./components/sensors/Sensors";

import "./newTheme/css/style.css";
import "./newTheme/css/plugins.css";
import "./newTheme/css/dashboard-style.css";
import ReviewRecievedUser from "./components/dashboard/ReviewRecievedUser";
import ReviewSentUser from "./components/dashboard/ReviewSentUser";
import SubscribePage from "./components/Pages/SubscribePage";
import TermsAndConditionsPage from "./components/Pages/TermsAndCoditionsPage";
import PrivacyPolicyPage from "./components/Pages/PrivacyPolicyPage";
import ForgotPasswordPage from "./components/Pages/ForgotPasswordPage";

class App extends Component {
    render() {
        return (
            <div className="h-screen App min-h-[600px]">
                {/* https://dev2.to/lautaro/dynamic-routes-in-react-router-v6-569m */}
                <Router>
                    <Routes>
                        {/* <Route path="/" exact element={<PrivateRoute><Home /></PrivateRoute>} /> */}
                        <Route path="/" exact element={<HomePage />} />
                        <Route path="/map" exact element={<MapPlaces />} />

                        <Route path="/register" exact element={<Register />} />
                        <Route path="/login" exact element={<Login />} />
                        <Route path="/logout" exact element={<Logout />} />
                        <Route path="/forgot-password" exact element={<ForgotPasswordPage />} />

                        <Route path="/terms" exact element={<TermsAndConditionsPage />} />
                        <Route path="/privacy-policy" exact element={<PrivacyPolicyPage />} />

                        <Route
                            path="/user/profile"
                            exact
                            element={
                                <PrivateRoute>
                                    <UserProfile />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/favorite"
                            exact
                            element={
                                <PrivateRoute>
                                    <FavoriteUser />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/recieved-reviews"
                            exact
                            element={
                                <PrivateRoute>
                                    <ReviewRecievedUser />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/sent-reviews"
                            exact
                            element={
                                <PrivateRoute>
                                    <ReviewSentUser />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/profile/edit"
                            exact
                            element={
                                <PrivateRoute>
                                    <UserProfileEdit />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/user/pointsofinterest"
                            exact
                            element={
                                <PrivateRoute>
                                    <UserPointsofinterest />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/experiences"
                            exact
                            element={
                                <PrivateRoute>
                                    <UserExperiences />
                                </PrivateRoute>
                            }
                        />

                        <Route path="/pointofinterest/:pointofinterestId" exact element={<PointOfInterestView />} />
                        <Route
                            path="/pointofinterest/create"
                            exact
                            element={
                                <PrivateRoute>
                                    <CreatePointofinterest />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/pointofinterest/:pointofinterestId/edit"
                            exact
                            element={
                                <PrivateRoute>
                                    <EditPointofinterest />
                                </PrivateRoute>
                            }
                        />

                        <Route path="/experience/:experienceId" exact element={<ExperienceView />} />
                        <Route
                            path="/experience/create"
                            exact
                            element={
                                <PrivateRoute>
                                    <CreateExperience />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/experience/:experienceId/edit"
                            exact
                            element={
                                <PrivateRoute>
                                    <EditExperience />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/user/pointsofinterest"
                            exact
                            element={
                                <PrivateRoute>
                                    <UserPointsofinterest />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/experiences"
                            exact
                            element={
                                <PrivateRoute>
                                    <UserExperiences />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/notifications"
                            exact
                            element={
                                <PrivateRoute>
                                    <UserNotifications />
                                </PrivateRoute>
                            }
                        />

                        <Route path="/sensors" exact element={<Sensors />} />

                        <Route path="/subscription" exact element={<SubscribePage />} />

                        <Route path="/error/404" exact element={<Error />}></Route>
                        <Route path="*" element={<Error />} />
                    </Routes>
                </Router>
            </div>
        );
    }
}

export default App;
