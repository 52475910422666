import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";

import axios from "axios";

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const toast = useRef(null);

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    const handleMouseEnter = (typeHover) => {
        switch (typeHover) {
            case 0:
                setIsHovered(true);
                break;
            case 1:
                setIsHovered1(true);
                break;
            case 2:
                setIsHovered2(true);
                break;
            default:
                break;
        }
    };

    const handleMouseLeave = (typeHover) => {
        switch (typeHover) {
            case 0:
                setIsHovered(false);
                break;
            case 1:
                setIsHovered1(false);
                break;
            case 2:
                setIsHovered2(false);
                break;
            default:
                break;
        }
    };

    const formik = useFormik({
        initialValues: {
            email: "",
        },

        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = "E-mail necesar.";
            }

            return errors;
        },
        onSubmit: (data) => {
            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL + "/forgot-password",
                data: {
                    email: data.email,
                },
            })
                .then((response) => {
                    showSuccess(response?.data?.message);

                    setTimeout(() => {
                        navigate("/login");
                    }, 10000);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.message) {
                            if (error.response.data.message.email) {
                                showError(error.response.data.message.email[0]);
                            } else {
                                showError(error.response.data.message);
                            }
                        } else {
                            showError("An unexpected error occurred.");
                        }
                    } else if (error.request) {
                        showError("No response received from the server.");
                    } else {
                        showError("An unexpected error occurred.");
                    }
                });

            formik.resetForm();
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const showSuccess = (detailValue) => {
        toast.current.show({
            severity: "success",
            summary: "Informația a fost trimisă cu succes",
            detail: detailValue + ".\nVei fi redirecționat în 10 secunde",
            life: 5000,
        });
    };

    const showError = (detailValue) => {
        toast.current.show({
            severity: "error",
            summary: "Informația nu a putut fi trimisă către server",
            detail: detailValue,
            life: 5000,
        });
    };

    return (
        <>
            <Toast className="mt-2" ref={toast} />
            <div className="bg-[#f6f6f6] h-full flex items-center justify-center">
                <div className="bg-white w-[500px] mx-auto rounded-lg">
                    <div className="pl-4 bg-[#4E65A3] rounded-t-lg text-white py-3">
                        <div className="font-bold">
                            Bine ați venit pe <span className="text-[rgb(77,183,254)]">SMART</span>SENSE
                            <span className="text-[rgb(77,183,254)]">.</span>
                        </div>
                    </div>

                    <div className="mx-4">
                        <div className="my-3 space-y-3">
                            <div className="grid grid-cols-2">
                                <Link
                                    to="/login"
                                    className="space-y-3 text-black hover:cursor-pointer"
                                    onMouseEnter={() => handleMouseEnter(1)}
                                    onMouseLeave={() => handleMouseLeave(1)}>
                                    <div className="flex flex-row mt-4 space-x-2 div1">
                                        <i className="text-sky-400 gmui gmui-login"></i>
                                        <span>Autentificare</span>
                                    </div>
                                    <div className={`h-px ${isHovered1 ? "bg-sky-400" : "bg-neutral-200"}`}></div>
                                </Link>
                                <Link
                                    to="/register"
                                    className="space-y-3 text-black hover:cursor-pointer"
                                    onMouseEnter={() => handleMouseEnter(2)}
                                    onMouseLeave={() => handleMouseLeave(2)}>
                                    <div className="flex flex-row mt-4 space-x-2">
                                        <i className="text-sky-400 gmui gmui-add-user"></i>
                                        <span>Inregistrare</span>
                                    </div>
                                    <div className={`h-px ${isHovered2 ? "bg-sky-400" : "bg-neutral-200"}`}></div>
                                </Link>
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} className="space-y-6 text-[rgb(135,140,159)] mb-6">
                            {/* Name */}
                            <div className="space-y-2 row">
                                <label
                                    htmlFor="email"
                                    className={classNames("ml-1 font-semibold", { "p-error": isFormFieldValid("email") })}>
                                    Adresă Email *
                                </label>
                                <div className="col-md-12">
                                    <InputText
                                        id="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        autoFocus
                                        className={classNames("w-full", { "p-invalid": isFormFieldValid("email") })}
                                    />
                                </div>
                                {getFormErrorMessage("email")}
                            </div>

                            <button
                                type="submit"
                                className="w-28 h-10 bg-[#384F95] rounded-md text-white px-4 flex items-center transition-transform duration-300 relative"
                                onMouseEnter={() => handleMouseEnter(0)}
                                onMouseLeave={() => handleMouseLeave(0)}>
                                <span>Trimite</span>
                                <i
                                    className={`text-xl text-sky-400 gmui gmui-arrow-right ${isHovered ? "ml-6" : "ml-3"}`}
                                    style={{ transition: "margin-left 300ms" }}></i>
                            </button>
                            {/* <div className="mt-3 float-btn" style={{ lineHeight: "2.75rem" }}>
                                Sau <a href="/register">completeaza formularul</a> pentru a crea un cont.
                            </div> */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordPage;
