import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import DashboardMenu from "../DashboardMenu";

import { useNavigate } from "react-router-dom";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function SubscribePage() {
    let userData = getUserData();

    let navigate = useNavigate();

    return (
        <div className="bg-[rgb(239,243,248)] min-h-full">
            <Header />
            <div className="container box-border py-8 mx-auto my-10 bg-white rounded-lg newtheme">
                <DashboardMenu />
                <div className="col-md-9 col-sm-12">
                    <div className="dashboard-title fl-wrap">
                        <h1 className="text-2xl text-[#566985]">Bine ai venit, {userData.user.name}!</h1>
                    </div>

                    <div className="profile-edit-container fl-wrap block_box">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="w-full bg-white rounded-lg">
                                    <h1 className="mb-2 text-xl text-[#566985]">Ai nevoie de credite!</h1>
                                    <div>Pentru a putea adăuga experiențe / puncte de interes este nevoie sa cumperi credite ....</div>
                                    <a href="#">Link Magazin</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SubscribePage;
