import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Error() {
    return (
        <div className="flex flex-col h-screen">
            <Header />
            <div className="flex flex-row items-center justify-center flex-grow">
                <div className="flex flex-col items-start">
                    <h1 className="text-3xl">Ceva nu e în regulă aici...</h1>
                    <span className="text-lg">
                        Nu putem găsi pagina pe care o căutați. Verificați centrul nostru de ajutor sau navigați înapoi la pagina
                        principală.
                    </span>
                    <Link to="/">
                        <button className="px-4 py-2 mt-6 text-base font-semibold text-white bg-gray-500 rounded-sm">Acasă</button>
                    </Link>
                </div>
                <img className="h-[30vh] max-h-[300px]" src="/questionmark.jpg" alt="" />
            </div>
            <Footer />
        </div>
    );
}

export default Error;
