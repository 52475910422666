import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Logout() {
    localStorage.removeItem("userData");

    return (
        <div className="bg-[rgb(239,243,248)] h-full py-10 min-h-[700px]">
            <div className="bg-white w-[500px] mx-auto rounded-lg py-8">
                <h1 className="text-xl text-center">Deconectare</h1>
                <p className="text-center text-l">Ai fost deconectat cu succes</p>
                <br />
                <p className="text-center text-l">Navighează către:</p>
                <br />
                <div className="text-center text-l">
                    <Link to="/login">Autentificare</Link>
                    <Link style={{ marginLeft: "30px" }} to="/">
                        Acasă
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Logout;
