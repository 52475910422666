import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchActions } from "../../slice/search/sliceSearch";
import { Rating } from "primereact/rating";
import styled from "styled-components";
import axios from "axios";

import "./style.css";

const StyledRating = styled(Rating)`
    .pi-star-fill {
        color: #facc39 !important;
        font-size: 13px;
    }
    .pi-star {
        color: rgba(255, 255, 255, 0.4);
        font-size: 13px;
    }
`;

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const Place = ({ place, linkTo, favExpIds, onLocation }) => {
    const userData = getUserData();
    const dispatch = useDispatch();
    const location = useLocation();
    const [redirect, setRedirect] = useState(false);

    let _userData = JSON.parse(JSON.stringify(userData));
    let favExperiencesIDs = [];

    if (userData)
        Object.keys(_userData.user.favorites).forEach((favExperienceKey) => {
            if (
                typeof _userData.user.favorites[favExperienceKey] === "object" &&
                !Array.isArray(_userData.user.favorites[favExperienceKey]) &&
                _userData.user.favorites[favExperienceKey] !== null
            ) {
                favExperiencesIDs.push(_userData.user.favorites[favExperienceKey].id);
            } else favExperiencesIDs = _userData.user.favorites;
        });

    let favorite = () => {
        let stateFav = false;
        favExperiencesIDs &&
            favExperiencesIDs.forEach((id) => {
                if (id === place.id) stateFav = true;
            });

        return stateFav;
    };
    const [toggleFavorite, setToggleFavorite] = useState(favorite());

    function redirectIfUserNotLoggedIn() {
        if (userData === null) setRedirect(true);
    }

    async function updateFavoriteExperience(experience) {
        if (!userData) alert("Pentru a putea adauga o experienta la favorite este necesar sa va inregistrati");

        let newData = favExperiencesIDs;
        const experienceId = experience.id;

        if (!newData.includes(experienceId)) {
            newData.push(experienceId);
        } else {
            newData.splice(newData.indexOf(experienceId), 1);
        }

        let _userData = JSON.parse(JSON.stringify(userData));
        let favExperiences = _userData.user.favorites;

        const i = favExperiences.findIndex((e) => e.id === experienceId);
        if (i > -1) {
            favExperiences.splice(i, 1);
        } else {
            favExperiences.push(experience);
        }
        _userData.user.favorites = favExperiences;

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `/web/users/${userData.user.id}/favorite-experiences`,
            data: { experiences_ids: newData },
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        })
            .then((response) => {
                setToggleFavorite(!toggleFavorite);
                localStorage.setItem("userData", JSON.stringify(_userData));
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log(error.message);
                }
            });
    }

    if (linkTo == undefined) linkTo = "pointofinterest";
    var _type = linkTo;
    //dragos sa verifici de ce in harta linkTo returneaza tot timpul undefined.

    const [showSecondDiv, setShowSecondDiv] = useState(false);

    const handleMouseEnter = () => {
        setShowSecondDiv(true);
    };

    const handleMouseLeave = () => {
        setShowSecondDiv(false);
    };

    return (
        <div className="clearfix listing-item">
            <article className="geodir-category-listing fl-wrap">
                <div className="geodir-category-img">
                    {!place.point_of_interest_category && (
                        <div
                            className="geodir-js-favorite_btn"
                            onClick={() => {
                                updateFavoriteExperience(place);
                                redirectIfUserNotLoggedIn();
                            }}>
                            {toggleFavorite ? (
                                <div className="relative w-24 h-8 hover:cursor-pointer">
                                    <div
                                        className="absolute w-8 h-8 text-2xl text-center bg-blue-700 rounded-full"
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}>
                                        <div className="gmui gmui-heart favoriteheart"></div>
                                    </div>
                                    {showSecondDiv && (
                                        <div
                                            className="flex items-center justify-end w-24 h-8 px-3 bg-blue-700 rounded-full save-text"
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}>
                                            Elimină
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="relative w-24 h-8 hover:cursor-pointer">
                                    <div
                                        className="absolute w-8 h-8 text-2xl text-center bg-blue-700 rounded-full favoriteheart2"
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}>
                                        ♡
                                    </div>
                                    {showSecondDiv && (
                                        <div
                                            className="flex items-center justify-end w-24 h-8 px-3 bg-blue-700 rounded-full save-text"
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}>
                                            Adaugă
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    <a href={`/${linkTo}/${place.id}`} className="geodir-category-img-wrap fl-wrap">
                        <img
                            src={
                                place?.images?.[0]?.url !== undefined
                                    ? `http://dev2.smartsense.ro/storage/${place?.images[0]?.url}`
                                    : "/default/default-image.png"
                            }
                            alt={place?.images?.title ? place?.images?.title : place?.title}
                        />
                    </a>
                    {/* <div className="listing-avatar"><a href="author-single.html"><img src="images/avatar/1.jpg" alt="" /></a>
                        <span className="avatar-tooltip">Added By  <strong>Alisa Noory</strong></span>
                    </div> */}
                    {place.start_date && place.end_date && (
                        <div className="geodir_status_date color-bg">
                            <i className="gmui gmui-calendar"></i>
                            {place.start_date.split(" ")[0] + " - " + place.end_date.split(" ")[0]}
                        </div>
                    )}
                    {place.average_review && (
                        <div className="geodir-category-opt">
                            <div className="listing-rating-count-wrap">
                                <div className="review-score">{place.average_review}</div>
                                <StyledRating value={Math.floor(place.average_review)} readOnly stars={5} cancel={false} />
                            </div>
                        </div>
                    )}
                </div>
                <div className="geodir-category-content fl-wrap">
                    <div className="geodir-category-content-title fl-wrap">
                        <div className="geodir-category-content-title-item">
                            <h3 className="title-sin_map">
                                <a href={`/${linkTo}/${place.id}`}>
                                    {place.title.length > 40 ? `${place.title.substring(0, 40)}...` : place.title}
                                </a>
                            </h3>
                            {place.location && (
                                <div className="geodir-category-location fl-wrap">
                                    <a href="#1" className="map-item">
                                        <i className="gmui gmui-map-marker"></i> {place.location}
                                    </a>
                                </div>
                            )}
                            {place.address && (
                                <div className="geodir-category-location fl-wrap">
                                    <a href="#1" className="map-item">
                                        <i className="gmui gmui-map-marker"></i> {place.address}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="geodir-category-text fl-wrap">
                        <p className="small-text">
                            {place.description.length > 200 ? `${place.description.substring(0, 200)}...` : place.description}
                        </p>
                        {place.tags && (
                            <div className="facilities-list fl-wrap">
                                <div className="facilities-list-title">Tags : </div>
                                <ul className="no-list-style">
                                    {place?.tags?.map((tag, index) => (
                                        <li className="tolt" data-microtip-position="top" key={index} data-tooltip={tag.tag}>
                                            <img className="tagicon" src={"http://dev2.smartsense.ro/storage/" + tag.icon_url}></img>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="geodir-category-footer fl-wrap">
                        {place.point_of_interest_category && (
                            <a className="listing-item-category-wrap">
                                <div className="listing-item-category red-bg">
                                    <img
                                        className="caticon"
                                        src={"http://dev2.smartsense.ro/storage/" + place.point_of_interest_category.icon_url}></img>
                                </div>
                                <span style={{ backgroundColor: place.color }}>{place.point_of_interest_category.title}</span>
                            </a>
                        )}
                        {_type == "experience" && (
                            <div className="facilities-list fl-wrap">
                                {place.youtube_url || place.website_url || place.facebook_url ? (
                                    <div className="facilities-list-title">Link-uri utile : </div>
                                ) : (
                                    <div className="text-center facilities-list-title w-100">
                                        <a href={`/${linkTo}/${place.id}`}>Mai multe informatii..</a>
                                    </div>
                                )}
                                <ul className="no-list-style linksList">
                                    {place.youtube_url && (
                                        <li className="tolt" data-microtip-position="top" data-tooltip={"Youtube"}>
                                            <a href={place.youtube_url} className="show_gcc">
                                                <i className="gmui gmui-youtube"></i>
                                            </a>
                                        </li>
                                    )}
                                    {place.website_url && (
                                        <li className="tolt" data-microtip-position="top" data-tooltip={"Website"}>
                                            <a href={place.website_url} className="show_gcc">
                                                <i className="gmui gmui-website"></i>
                                            </a>
                                        </li>
                                    )}
                                    {place.facebook_url && (
                                        <li className="tolt" data-microtip-position="top" data-tooltip={"Facebook"}>
                                            <a href={place.facebook_url} className="show_gcc">
                                                <i className="gmui gmui-facebook"></i>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                        <div className="geodir-opt-list">
                            <ul className="no-list-style">
                                {place.phone_number && (
                                    <li>
                                        <a href={"tel:" + place.phone_number} className="show_gcc">
                                            <i className="gmui gmui-phone"></i>
                                            <span className="geodir-opt-tooltip">Contactează</span>
                                        </a>
                                    </li>
                                )}
                                {onLocation != "map" && place.latitude && place.longitude && (
                                    <li>
                                        <a
                                            href="#"
                                            onClick={() => {
                                                linkTo === "pointofinterest" && dispatch(searchActions.getPointOfInterest(place));
                                                linkTo === "experience" && dispatch(searchActions.getExperience(place));
                                            }}
                                            className="map-item">
                                            <i className="gmui gmui-map-marker"></i>
                                            <span className="geodir-opt-tooltip"> Arată pe hartă </span>{" "}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
};

export default Place;
