import CarouselPOI from "../CarouselPOI";
import Layout from "./Layout";

const Carousel = ({ experience }) => {
    return (
        <Layout title="Puncte de interes">
            <div id="submenuCarouselPoi"></div>
            {experience && <CarouselPOI experienceId={experience.id} />}
        </Layout>
    );
};

export default Carousel;
