import React from "react";
import { Navigate } from "react-router-dom";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const PrivateRoute = ({ children }) => {
    // const location = useLocation();
    const isAuthenticated = getUserData();

    // return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />;

    let paths = [];
    paths["noauth"] = ["MapPlaces", "Register", "Login", "Logout", "Sensors", "Sensor", "Error"];
    paths["person"] = [
        "MapPlaces",
        "Register",
        "Login",
        "Logout",
        "Sensors",
        "Sensor",
        "Error",
        "UserProfile",
        "UserProfileEdit",
        "FavoriteMapComp",
        "UserNotifications",
        "FavoriteUser",
        "ReviewRecievedUser",
        "ReviewSentUser",
    ];
    paths["company"] = [
        "MapPlaces",
        "Register",
        "Login",
        "Logout",
        "Sensors",
        "Sensor",
        "Error",
        "UserProfile",
        "UserProfileEdit",
        "FavoriteMapComp",
        "CreatePointofinterest",
        "EditPointofinterest",
        "CreateExperience",
        "EditExperience",
        "UserPointsofinterest",
        "UserExperiences",
        "UserNotifications",
        "FavoriteUser",
        "ReviewRecievedUser",
        "ReviewSentUser",
    ];
    paths["admin"] = [
        "MapPlaces",
        "Register",
        "Login",
        "Logout",
        "Sensors",
        "Sensor",
        "Error",
        "UserProfile",
        "UserProfileEdit",
        "FavoriteMapComp",
        "CreatePointofinterest",
        "EditPointofinterest",
        "CreateExperience",
        "EditExperience",
        "UserPointsofinterest",
        "UserExperiences",
        "UserNotifications",
        "FavoriteUser",
        "ReviewRecievedUser",
        "ReviewSentUser",
    ];

    if (isAuthenticated) {
        var code = "";
        if (isAuthenticated.user.roles.some((e) => e.code == "company")) code = "company";
        else if (isAuthenticated.user.roles.some((e) => e.code == "person")) code = "person";
        else code = "noauth";

        if (paths[code].includes(children.type.name)) return children;
        else return <Navigate to="/subscription" />;
    }

    return <Navigate to="/login" />;
};

export default PrivateRoute;
