import React, { useState } from "react";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const DashboardMenu = () => {
    const [current, setCurrent] = useState(window.location.pathname);
    let userData = getUserData();

    return (
        <div className="col-md-3 col-sm-12 floating-menu">
            <div className="fixed-bar fl-wrap" id="dash_menu">
                <div className="user-profile-menu-wrap fl-wrap block_box">
                    <div className="user-profile-menu">
                        <h3>Generale</h3>
                        <ul className="no-list-style">
                            <li>
                                <a className={"color2hover " + (current == "/user/profile" ? "user-profile-act" : "")} href="/user/profile">
                                    <i className="gmui gmui-chart-line"></i> Panou de comanda
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"color2hover " + (current == "/user/favorite" ? "user-profile-act" : "")}
                                    href="/user/favorite">
                                    {" "}
                                    <i className="gmui gmui-heart"></i> Favorite
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"color2hover " + (current == "/user/notifications" ? "user-profile-act" : "")}
                                    href="/user/notifications">
                                    {" "}
                                    <i className="gmui gmui-rss"></i> Notificări
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"color2hover " + (current == "/user/profile/edit" ? "user-profile-act" : "")}
                                    href="/user/profile/edit">
                                    <i className="gmui gmui-user-edit"></i> Editează profilul
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-3 user-profile-menu">
                        <h3>Experiențe și puncte de interes</h3>
                        <ul className="no-list-style">
                        {userData?.user?.roles.some((e) => e.code == "company") && (
                            <>
                                <li>
                                    <a
                                        className={"color2hover " + (current == "/user/experiences" ? "user-profile-act" : "")}
                                        href="/user/experiences">
                                        <i className="gmui gmui-th-list"></i> Experiențe{" "}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={"color2hover " + (current == "/user/pointsofinterest" ? "user-profile-act" : "")}
                                        href="/user/pointsofinterest">
                                        <i className="gmui gmui-th-list"></i> Puncte de interes{" "}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={"color2hover " + (current == "/experience/create" ? "user-profile-act" : "")}
                                        href="/experience/create">
                                        <i className="gmui gmui-file-plus"></i> Adaugă o experiență
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={"color2hover " + (current == "/pointofinterest/create" ? "user-profile-act" : "")}
                                        href="/pointofinterest/create">
                                        <i className="gmui gmui-file-plus"></i> Adaugă un punct de interes
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className={"color2hover " + (current == "/user/recieved-reviews" ? "user-profile-act" : "")}
                                        href="/user/recieved-reviews">
                                        <i className="gmui gmui-star"></i> Recenzii primite{" "}
                                    </a>
                                </li>
                            </>
                        )}
                            <li>
                                <a
                                    className={"color2hover " + (current == "/user/sent-reviews" ? "user-profile-act" : "")}
                                    href="/user/sent-reviews">
                                    <i className="gmui gmui-star"></i> Recenzii trimise{" "}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a className="back-tofilters color2-bg custom-scroll-link logout_btn" href="/logout">
                            Deconectare <i className="gmui gmui-sign-out"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
    );
};

export default DashboardMenu;
