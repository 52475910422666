import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { FileUpload } from "primereact/fileupload";
import { useDeleteUserMutation } from "../../services/smartsenseApi";

import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import DashboardMenu from "../DashboardMenu";

import { useNavigate } from "react-router-dom";
import Moment from "moment";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function UserProfileEdit() {
    let userData = getUserData();
    const [deleteUserRequest, deleteUserRequestResponse] = useDeleteUserMutation();
    const [unDeleteUserRequest, unDeleteUserRequestResponse] = useDeleteUserMutation();
    const [showDeleteComp, setShowDeleteComp] = useState(false);
    const [deletedImages, setDeletedImages] = useState([]);

    Moment.locale("en");
    var dt1 = "2023-05-02T00:00:00";
    var dt2 = "2024-05-02T00:00:00";

    var today = new Date();
    var ndate = new Date(new Date().setDate(today.getDate() + 30));
    if (!userData.user.delete_account_at) userData.user.delete_account_at = null;
    var future = new Date(userData.user.delete_account_at);

    // console.log("userData1", userData);
    userData = {
        id: userData.user.id,
        delete_account_at: userData.user.delete_account_at,
        name: userData.user.name,
        email: userData.user.email,
        avatar: userData.user.avatar,
        isCompany: userData?.user?.roles.some((e) => e.code == "company") ? true : false,
        token: userData.token.split("|")[1],
        password: userData?.password,

        companyAddress: userData?.user?.company?.address ? userData?.user?.company?.address : "Nu există informația",
        companyName: userData?.user?.company?.name ? userData?.user?.company?.name : "Nu există informația",
        companyCui: userData?.user?.company?.cui ? userData?.user?.company?.cui : "Nu există informația",
        companyReg_com: userData?.user?.company?.reg_com ? userData?.user?.company?.reg_com : "Nu există informația",

        subscriptionType: userData.user.subscriptionType ? userData.user.subscriptionType : null,
        subscriptionFrom: Moment(userData.subscriptionFrom).format("d/M/y"),
        subscriptionTo: Moment(userData.subscriptionTo).format("d/M/y"),
    };

    let navigate = useNavigate();
    const [theImages, setTheImages] = useState([userData.avatar]);

    const formik = useFormik({
        initialValues: {
            name: userData.name,
            email: userData.email,
            current_password: userData.password,
            new_password: "",
            new_confirm_password: "",
            avatar: null,
            // isCompany: true,
            // companyName: "",
            // cif: "",
            // subscriptionType: "",
            // subscriptionFrom: "",
            // subscriptionTo: "",
            // accept: false,
        },

        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = "Nume necesar.";
            }

            if (!data.email) {
                errors.email = "Email necesar.";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = "Invalid email address. E.g. example@email.com";
            }

            if(data.password)
            {
                if (!data.new_confirm_password) {
                    errors.new_confirm_password = "Confirmarea parolei necesara.";
                } else if (data.new_password !== data.new_confirm_password) {
                    errors.new_confirm_password = "Parolele trebuie sa fie identice";
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            // console.log(userData);
            data.current_password = userData.password;

            if(!data.new_password)
            {
                delete data.new_password;
                delete data.new_confirm_password;
            }
            // console.log(data);
            
            // return false;
            axios({
                method: "put",
                url: process.env.REACT_APP_API_URL + "/web/users/" + userData.id,
                data: data,
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            }).then(
                (response) => {
                    userData = JSON.parse(localStorage.getItem("userData"));
                    userData.user = response.data.data;
                    localStorage.setItem("userData", JSON.stringify(userData));
                    navigate("/user/profile");
                },
                (error) => {
                    alert("Modificarea informatiilor a esuat!");

                    console.log(error);
                }
            );

            formik.resetForm();
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const passwordHeader = <h6>Alege o parola</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Sugestii</p>
            <ul className="pl-2 mt-0 ml-2" style={{ lineHeight: "1.5" }}>
                <li>Cel putin o litera mare</li>
                <li>Cel putin o litera mica</li>
                <li>Cel putin o cifra</li>
                <li>Cel putin 8 caractere</li>
            </ul>
        </React.Fragment>
    );

    const deleteUser = (userId) => {
        userData = JSON.parse(localStorage.getItem("userData"));
        userData.user.delete_account_at = ndate;
        localStorage.setItem("userData", JSON.stringify(userData));

        deleteUserRequest(userId);
        setShowDeleteComp(false);
    };

    const unDeleteUser = (userId) => {
        userData = JSON.parse(localStorage.getItem("userData"));
        userData.user.delete_account_at = null;
        localStorage.setItem("userData", JSON.stringify(userData));

        unDeleteUserRequest(userId);
    };

    const tip_cont = [
        { name: "Persoana fizica", code: "pf" },
        { name: "Persoana juridica", code: "pj" },
    ];

    const customBase64Uploader = async (event) => {
        let images = event.files;
        let temp = {};

        const fileReader = new FileReader();
        fileReader.readAsDataURL(images[0]);
        fileReader.onload = () => {
            var result = fileReader.result;

            formik.handleChange({
                target: { name: "avatar", value: result },
            });

            temp.push({
                title: images[0].name,
                image: result,
            });
        };
    };

    return (
        <div className="bg-[rgb(239,243,248)] min-h-full">
            {today >= future ? (
                <>
                    {showDeleteComp && (
                        <div
                            id="defaultModal"
                            tabIndex="-1"
                            aria-hidden="true"
                            style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
                            className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
                            <div
                                className="w-full h-full max-w-2xl p-4 md:h-auto"
                                style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <div className="flex items-start justify-between p-2 ">
                                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Stergere cont</h3>
                                        <button
                                            type="button"
                                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                            data-modal-toggle="defaultModal">
                                            <svg
                                                aria-hidden="true"
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"></path>
                                            </svg>
                                            <span className="sr-only">Inchide popup</span>
                                        </button>
                                    </div>
                                    <div className="p-6 space-y-6">
                                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                            Sunteti sigur(a) ca doriti sa va stergeti contul?
                                        </p>
                                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                            Timp de 30 de zile vei avea posibiliatea de a-ti reactiva contul fara a pierde informatiile deja
                                            stocate
                                        </p>
                                    </div>
                                    <div className="flex items-center p-6 space-x-2">
                                        <button
                                            onClick={() => deleteUser(userData.id)}
                                            data-modal-toggle="defaultModal"
                                            type="button"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Șterge
                                        </button>
                                        <button
                                            onClick={() => setShowDeleteComp(false)}
                                            data-modal-toggle="defaultModal"
                                            type="button"
                                            className="px-5 py-2 font-medium border-2 rounded-lg text-neutral-500 border-neutral-300 hover:text-neutral-600 hover:border-neutral-400 hover:border-4">
                                            Anulează
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Header />
                    <div className="container box-border py-8 mx-auto bg-white rounded-lg newtheme">
                        <DashboardMenu />
                        <div className="col-md-9 col-sm-12">
                            <form onSubmit={formik.handleSubmit} className="py-4 space-y-6">
                                <div className="dashboard-title fl-wrap">
                                    <h1 className="text-2xl text-[#566985]">Modifică detaliile contului</h1>
                                </div>

                                <div className="space-y-5 profile-edit-container fl-wrap block_box text-[#666] font-medium">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="name" className={classNames("mb-2", { "p-error": isFormFieldValid("name") })}>
                                                Nume *
                                            </label>
                                            <div className="relative">
                                                <InputText
                                                    id="name"
                                                    name="name"
                                                    defaultValue={userData.name}
                                                    onChange={formik.handleChange}
                                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("name") })}
                                                />
                                                <i className="absolute transform gmui gmui-user top-3.5 left-3"></i>
                                            </div>
                                        </div>
                                        {getFormErrorMessage("name")}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="email" className={classNames("mb-2", { "p-error": isFormFieldValid("email") })}>
                                                Email *
                                            </label>

                                            <div className="relative">
                                                <InputText
                                                    id="email"
                                                    name="email"
                                                    disabled
                                                    defaultValue={userData.email}
                                                    onChange={formik.handleChange}
                                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("email") })}
                                                />
                                                <i className="absolute transform gmui gmui-mail top-3.5 left-3"></i>
                                            </div>
                                        </div>
                                        {getFormErrorMessage("email")}
                                    </div>
                                    <div className="mt-4 row avatarupload">
                                        {theImages?.length > 0 && (
                                            <div className="col-md-2" style={{ textAlign: "center" }}>
                                                {theImages.map(function (object, i) {
                                                    return (
                                                        <div className="avatar_dashb_img_container">
                                                            <div className="avatar_dashb_img">
                                                                <img src={object}></img>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}

                                        <FileUpload
                                            customUpload
                                            onSelect={customBase64Uploader}
                                            className="col-md-10"
                                            name="avatar"
                                            multiple
                                            accept="image/*"
                                            emptyTemplate={
                                                <div className="fu-text">
                                                    <span>
                                                        <i className="fa fal-images gmui gmui-images"></i> Drag-and-drop <b>aici</b> o
                                                        imagine de profil nouă
                                                    </span>
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label
                                                htmlFor="password"
                                                className={("mb-2", classNames("mb-2", { "p-error": isFormFieldValid("new_password") }))}>
                                                Parola *
                                            </label>
                                            <div className="relative">
                                                <Password
                                                    id="new_password"
                                                    name="new_password"
                                                    value={formik.values.new_password}
                                                    onChange={formik.handleChange}
                                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("password") })}
                                                    header={passwordHeader}
                                                    footer={passwordFooter}
                                                />
                                                <i className="absolute transform gmui gmui-lock top-3.5 left-3 z-10"></i>
                                            </div>
                                        </div>
                                        {getFormErrorMessage("password")}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label
                                                htmlFor="new_confirm_password"
                                                className={classNames("mb-2", { "p-error": isFormFieldValid("new_confirm_password") })}>
                                                Confirma parola *
                                            </label>
                                            <div className="relative">
                                                <Password
                                                    id="new_confirm_password"
                                                    name="new_confirm_password"
                                                    value={formik.values.new_confirm_password}
                                                    onChange={formik.handleChange}
                                                    toggleMask
                                                    className={classNames("w-full", { "p-invalid": isFormFieldValid("new_confirm_password") })}
                                                    feedback={false}
                                                />
                                                <i className="absolute transform gmui gmui-lock top-3.5 left-3 z-10"></i>
                                            </div>
                                        </div>
                                        {getFormErrorMessage("new_confirm_password")}
                                    </div>
                                </div>

                                {userData.isCompany && (
                                    <>
                                        <div className="dashboard-title dt-inbox fl-wrap">
                                            <h3 className="text-[#566985]">Datele companiei</h3>
                                        </div>

                                        <div className="space-y-4 profile-edit-container fl-wrap block_box text-[#666] font-medium">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label
                                                        htmlFor="companyName"
                                                        className={classNames("mb-2", { "p-error": isFormFieldValid("companyName") })}>
                                                        Denumire companie *
                                                    </label>
                                                    <div className="relative">
                                                        <InputText
                                                            id="companyName"
                                                            name="companyName"
                                                            defaultValue={userData.companyName}
                                                            disabled
                                                            onChange={formik.handleChange}
                                                            className={classNames("w-full", {
                                                                "p-invalid": isFormFieldValid("companyName"),
                                                            })}
                                                        />
                                                        <i className="absolute transform gmui gmui-company top-3.5 left-3 z-10"></i>
                                                    </div>
                                                </div>
                                                {getFormErrorMessage("companyName")}
                                            </div>

                                            <div className="row">
                                                <span className="col-md-12">
                                                    <label
                                                        htmlFor="companyAddress"
                                                        className={classNames("mb-2", { "p-error": isFormFieldValid("companyAddress") })}>
                                                        Adresă *
                                                    </label>
                                                    <div className="relative">
                                                        <InputText
                                                            id="companyAddress"
                                                            name="companyAddress"
                                                            defaultValue={userData.companyAddress}
                                                            disabled
                                                            onChange={formik.handleChange}
                                                            className={classNames("w-full", {
                                                                "p-invalid": isFormFieldValid("companyAddress"),
                                                            })}
                                                        />
                                                        <i className="absolute transform gmui gmui-map-marker top-3.5 left-3 z-10"></i>
                                                    </div>
                                                </span>
                                                {getFormErrorMessage("companyAddress")}
                                            </div>

                                            <div className="row">
                                                <span className="col-md-12">
                                                    <label
                                                        htmlFor="companyCui"
                                                        className={classNames("mb-2", { "p-error": isFormFieldValid("companyCui") })}>
                                                        CIF *
                                                    </label>
                                                    <div className="relative">
                                                        <InputText
                                                            id="companyCui"
                                                            name="companyCui"
                                                            defaultValue={userData.companyCui}
                                                            disabled
                                                            onChange={formik.handleChange}
                                                            className={classNames("w-full", {
                                                                "p-invalid": isFormFieldValid("companyCui"),
                                                            })}
                                                        />
                                                        <i className="absolute transform gmui gmui-cif top-3.5 left-3 z-10"></i>
                                                    </div>
                                                </span>
                                                {getFormErrorMessage("companyCui")}
                                            </div>

                                            <div className="row">
                                                <span className="col-md-12">
                                                    <label
                                                        htmlFor="companyReg_com"
                                                        className={classNames("mb-2", { "p-error": isFormFieldValid("companyReg_com") })}>
                                                        Registrul Comerțului *
                                                    </label>
                                                    <div className="relative">
                                                        <InputText
                                                            id="companyReg_com"
                                                            name="companyReg_com"
                                                            defaultValue={userData.companyReg_com}
                                                            disabled
                                                            onChange={formik.handleChange}
                                                            className={classNames("w-full", {
                                                                "p-invalid": isFormFieldValid("companyReg_com"),
                                                            })}
                                                        />
                                                        <i className="absolute transform gmui gmui-scale top-3.5 left-3 z-10"></i>
                                                    </div>
                                                </span>
                                                {getFormErrorMessage("companyReg_com")}
                                            </div>
                                        </div>
                                    </>
                                )}

                                {/* 
                            <div className="w-full field">
                                <span className="w-full p-float-label">
                                    <Dropdown
                                        id="isCompany"
                                        name="isCompany"
                                        value={userData.isCompany}
                                        op
                                        onChange={formik.handleChange}
                                        className={classNames("w-full", { "p-invalid": isFormFieldValid("isCompany") })}
                                    />
                                    <Dropdown value={userData.isCompany} options={tip_cont} onChange={(e) => setSelectedCountry(e.value)} optionLabel="name" filter showClear filterBy="name" placeholder="Select a Country" />
                                    <label htmlFor="isCompany" className={classNames({ "p-error": isFormFieldValid("isCompany") })}>
                                        Tip cont*
                                    </label>
                                </span>
                                {getFormErrorMessage("isCompany")}
                            </div> */}

                                <div className="pt-10 row">
                                    <div className="flex justify-evenly">
                                        <Button
                                            type="submit"
                                            label="Salvează"
                                            className="back-tofilters color2-bg custom-scroll-link logout_btn"
                                        />

                                        <button
                                            onClick={() => setShowDeleteComp(true)}
                                            className="px-3 py-2 font-medium text-red-500 rounded-md shadow-md hover:bg-neutral-200">
                                            Șterge contul
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Footer />
                </>
            ) : (
                <>
                    <Header />
                    <div className="container box-border py-8 mx-auto bg-white rounded-lg newtheme">
                        <DashboardMenu />

                        <div className="col-md-9 col-sm-12">
                            <div className="py-4 space-y-6">
                                <div className="dashboard-title fl-wrap">
                                    <h1 className="text-2xl text-[#566985] ">Bine ai revenit, {userData.name}!</h1>
                                </div>
                                <div className="space-y-5 profile-edit-container fl-wrap block_box text-[#666] font-medium">
                                    <div className="w-full">
                                        <div className="bg-white rounded-lg">
                                            <h1 className="text-lg text-center">
                                                Contul tau este dezactivat. Pana la data de xxx iti poti reactiva contul fara a pierde
                                                accesul la:
                                            </h1>

                                            <ul className="my-10 text-[#666] font-medium text-center">
                                                <li>Experiente favorite</li>
                                                <li>Puncte de interes favorite</li>
                                                <li>Alte chestii</li>
                                                <li>Alte chestii</li>
                                                <li>Alte chestii</li>
                                            </ul>

                                            <Button
                                                type="button"
                                                fontSize="small"
                                                onClick={() => unDeleteUser(userData.id)}
                                                label="Reactivează acum"
                                                className="newtheme-submit"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            )}
        </div>
    );
}

export default UserProfileEdit;
