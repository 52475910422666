import React, { useEffect, useState, useRef } from "react";

import { useGetStationsQuery, useGetStationSensorsQuery } from "../../services/smartsenseApi";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from 'primereact/tooltip';
import { Calendar } from 'primereact/calendar';
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";

const getUserData = () => {
    const user = localStorage.getItem('userData');
    return user ? JSON.parse(user) : null;
};

function Sensors() {
    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const [loading, setLoading] = useState(true);
    const [stations, setStations] = useState([]);
    const [sensor, setSensor] = useState(null);
    const [sensors, setSensors] = useState(null);
    const [sensorName, setSensorName] = useState(null);
    const [sensorNames, setSensorNames] = useState(null);
    const [thesensor, setThesensor] = useState(null);
    const [sensorUnit, setSensorUnit] = useState(null);
    const [stationid, setStationid] = useState(0);
    const [theTemp, setTheTemp] = useState(null);
    const [currentTemp, setCurrentTemp] = useState(0);
    const [currentPres, setCurrentPres] = useState(0);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [station, setStation] = useState(null);
    const [chartOptions, setChartOptions] = useState([]);
    const [chartSeries, setChartSeries] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [reloadData, setReloadData] = useState(1);
    const dt = useRef(null);

    const { data: _stations, isFetching: isFetchingStations, error: errorStations } = useGetStationsQuery(); //used

    useEffect(() => {
        if (!isFetchingStations) {
            setStations(_stations?.data);
            setLoading(false);
        }
        setStartDate(yesterday);
        setEndDate(today);
    }, [isFetchingStations]);


    useEffect(() => {
        if (!!stationid) {

            axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/web/stations/' + stationid + '/sensors'
            })
            .then((response) => {

                var _sensorNames = [];
                var _sensors = response?.data?.data;

                _sensors.forEach(answer => {
                    _sensorNames.push(answer.name);
                })

                setSensors(_sensors);
                setSensorNames(_sensorNames);
                if(!sensorName)
                {
                    timeout(2000,null,_sensors[0]);
                }
                else
                {
                    timeout(2000,sensorName);
                }

                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_URL + '/web/stations/' + stationid + '/data',
                    params : {
                        start_date: startDate,
                        end_date: endDate
                    }
                })
                .then((response) => {
                    console.log(response.data.data);
                    var _resp = response.data.data;
                    doCalculus(_resp.current_humidity.split(' ')[0],_resp.current_temperature.split(' ')[0], "°C");
                    setCurrentTemp(_resp.current_temperature);
                    setCurrentPres(_resp.current_pressure);
                    setMax(_resp.max_temperature);
                    setMin(_resp.min_temperature);
                    setLoading(false);
                }, (error) => {
                    console.log(error);
                });

            }, (error) => {
                console.log(error);
            });
        }

    }, [stationid, reloadData]);

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    const findSensor = (sensorName) => {
        return sensors.find(x => x.name == sensorName);
    }

    const doCalculus = (airHum, airTemp, unit) => {
        let prag = [0, 1];
        let raport = 0;
        let returnTemp = 0;
        let step_l = 0.01;
        let step_h = 0.02;
        let fl43 = 1.15;
        let fh43 = 1.66;
        let fl22 = 1;
        let fh22 = 1.390;
        let praguri = [];
        praguri['22'] = [22, 31];
        praguri['23'] = [23, 33];
        praguri['24'] = [24, 35];
        praguri['25'] = [25, 37];
        praguri['26'] = [26, 39];
        praguri['27'] = [27, 41];
        praguri['28'] = [28, 43];
        praguri['29'] = [29, 46];
        praguri['30'] = [30, 48];
        praguri['31'] = [32, 50];
        praguri['32'] = [33, 53];
        praguri['33'] = [34, 55];
        praguri['34'] = [36, 58];
        praguri['35'] = [37, 61];
        praguri['36'] = [39, 63];
        praguri['37'] = [40, 66];
        praguri['38'] = [42, 69];
        praguri['39'] = [43, 72];
        praguri['40'] = [45, 75];
        praguri['41'] = [46, 79];
        praguri['42'] = [48, 82];

        if (airHum >= 25) {
            if (airTemp >= 22 && airTemp <= 42) {
                prag = praguri[Math.floor(airTemp)];
            }
            else if (airTemp >= 43) {
                fl43 += step_l * (airTemp - 42);
                const low = airTemp * fl43;
                fh43 += step_h * (airTemp - 42);
                const high = low * fh43;
                prag = [low, high];
            }
            else if (airTemp < 22) {
                const low = airTemp * fl22;
                fh22 -= step_h * (22 - airTemp);
                const high = low * fh22;
                prag = [low, high];
            }
            raport = (prag[1] - prag[0]) / (100 - 25);
            returnTemp = prag[1] - raport * (100 - airHum);
        }

        setTheTemp(returnTemp.toFixed(0) + " " + unit);
    }

    const doChart = (data, name = null, measurement_unit) => {
        let _chartDates = [];
        let _chartSeriesData = [];

        data.map((chartdata, i) => {
            _chartDates.push(chartdata.date);
            _chartSeriesData.push(chartdata.value);
        })

        const chartoptions = {
            animations: {
                enabled: false
            },
            chart: {
                id: "basic-bar",
            },
            xaxis: {
                categories: _chartDates
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return value+" "+measurement_unit;
                    }
                },
            },
        };
    
        const chartseries = [
            {
                name: name,
                data: _chartSeriesData
            }
        ]

        setChartOptions(chartoptions);
        setChartSeries(chartseries);
    }

    const timeout = async (delay, name = null, _sensor = null) => {
        setLoading(true);

        if(!!_sensor)
        {
            setSensorName(_sensor.name);
            name = _sensor.name;
        }
        else
        {
            setSensorName(name);
            _sensor = findSensor(name);
        }
        console.log(_sensor.measurement_unit);
        setSensorUnit(_sensor.measurement_unit);

        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/web/sensors/' + _sensor.id + '/values',
            params : {
                start_date: startDate,
                end_date: endDate
            }
        })
        .then((response) => {
            var _response = response?.data?.data.table_data;
            setSensor(name);
            _response = _response.map(obj => ({ ...obj, name: name }))
            setThesensor(_response);
            doChart(response?.data?.data?.chart_data, name, _sensor.measurement_unit);
        }, (error) => {
            console.log(error);
        });

        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        // return () => {
        //     clearTimeout(timer);
        // }
    }

    const header = (
        <div className="col-12 align-items-center export-buttons" style={{ height: "51px" }}>
            <div className="col-md-8 col-sm-8 col-xs-8" style={{ lineHeight: "51px" }}>Descarca datele senzorului</div>
            {/* <Button type="button" value="Selectia curenta" icon="pi pi-filter" onClick={() => exportCSV(true)} className="p-button-info float-right" data-pr-tooltip="Selectia curenta" /> */}
            <Button type="button" value="Toate datele" icon="pi pi-file" style={{ marginRight: "10px" }} onClick={() => exportCSV(false)} className="float-right mr-10" data-pr-tooltip="Tot tabelul" />
        </div>
    );
    
    return (
        <>
            {loading && <div style={{ position: "absolute", width: "100%", height: "100%", background: "rgba(0,0,0,0.6)", zIndex: "99" }}>
                <div className="p-datatable-loading-overlay p-component-overlay"><i className="p-datatable-loading-icon pi-spin pi pi-spinner superCenter" style={{ color: "#fff", fontSize: "50px" }}></i></div>
            </div>}

            <div className="bg-[rgb(239,243,248)] min-h-full pb-1">
                <Header />
                <div className="max-w-7xl p-10 box-border py-8 mx-auto my-10 bg-white rounded-lg">
                    <div className="row">
                        <div className="col-md-4 field mb-6">
                            <h1 className="mb-0 mt-1 text-2xl">Pasul 1: Selecteaza statia</h1>
                        </div>
                        <div className="col-md-8 field">
                            <span className="p-float-label">
                                <Dropdown
                                    id="status"
                                    name="status"
                                    value={station}
                                    onChange={(event) => {
                                        setLoading(true);
                                        setStationid(event.value.id)
                                        setStation(event.value);
                                    }}
                                    options={stations}
                                    optionLabel="name"
                                    className="col-12"
                                />
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 field mb-6">
                            <h1 className="mb-0 mt-1 text-2xl"><small style={{ color: "rgb(185,178,178)" }}>(Optional)</small> Filtreaza dupa data</h1>
                        </div>
                        <div className="col-md-8 field mb-2">
                            <div className="px-0 mb-2 col-md-5 col-sm-12">
                                <Calendar dateFormat="dd/mm/yy" className="col-12 pr-2" value={startDate} onChange={(e) => setStartDate(e.value)}></Calendar>
                            </div>
                            <div className="px-0 mb-2 col-md-5 col-sm-12">
                                <Calendar dateFormat="dd/mm/yy" className="col-12 pr-2" value={endDate} onChange={(e) => setEndDate(e.value)}></Calendar>
                            </div>
                            <div className="px-0 mb-4 col-md-2 col-sm-12">
                                <Button type="button" value="Filtreaza" className="float-right col-12" data-pr-tooltip="Filtreaza"
                                    onClick={(event) => {
                                        setLoading(true);
                                        setMax("N\/A");
                                        setReloadData(reloadData + 1);
                                        setChartSeries(null);
                                        setThesensor(null);
                                        setSensor(null);
                                    }}
                                >Filtreaza</Button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {sensors &&
                            <div className="col-md-4 field mb-6 pr-10">
                                {max != "N\/A" && 
                                    <div className="hiddenmobile col-12 mb-6">
                                        <h2>Date curente</h2>
                                        <div className="col-12 row">
                                            <div className="col-6">
                                                <p>Temperatura maxima</p>
                                            </div>
                                            <div className="col-6">
                                                {max}
                                            </div>
                                        </div>
                                        <div className="col-12 row">
                                            <div className="col-6">
                                                <p>Temperatura minima</p>
                                            </div>
                                            <div className="col-6">
                                                {min}
                                            </div>
                                        </div>
                                        <div className="col-12 row">
                                            <div className="col-6">
                                                <p>Temperatura curenta</p>
                                            </div>
                                            <div className="col-6">
                                                {currentTemp}
                                            </div>
                                        </div>
                                        <div className="col-12 row">
                                            <div className="col-6">
                                                <p>Presiune curenta</p>
                                            </div>
                                            <div className="col-6">
                                                {currentPres}
                                            </div>
                                        </div>
                                        <div className="col-12 row">
                                            <div className="col-6">
                                                <p>Temperatura resimtita</p>
                                            </div>
                                            <div className="col-6">
                                                {theTemp}
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-12field mb-6">
                                    <div className="col-12 field mb-6">
                                        <h1 className="mb-0 mt-1 text-2xl">Pasul 2: Selecteaza senzorul</h1>
                                    </div>
                                    <div className="col-12 field">
                                        <span className="p-float-label">
                                            <Dropdown
                                                id="status"
                                                className="col-12"
                                                name="status"
                                                placeholder="Sensor"
                                                disabled={loading}
                                                onChange={(event) => {
                                                    timeout(1000, event.value);
                                                }}
                                                options={sensorNames}
                                                value={sensorName ? sensorName : sensorNames[0]}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }

                        {thesensor && <>
                            <div className="col-md-8 field mb-6">
                                {chartSeries.length != 0 &&
                                    <Chart
                                        options={chartOptions}
                                        series={chartSeries}
                                        type="line"
                                        className="col-12"
                                        height="300"
                                    />
                                }
                                {thesensor.length != 0 ? (
                                    <DataTable ref={dt} value={thesensor} header={header} paginator responsiveLayout="stack"
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                                        <Column header="Tip senzor" field="name"></Column>
                                        <Column header="Data" field="date"></Column>
                                        <Column align="right" header={"Valoare ("+sensorUnit+")"} field="value"></Column>
                                    </DataTable>
                                    ):(
                                        <h2 className="text-lg">Nu există rezultate pe perioada de timp selectată &#40; {startDate.toLocaleDateString('en-GB', { timeZone: 'UTC' })} - {endDate.toLocaleDateString('en-GB', { timeZone: 'UTC' })} &#41; </h2>
                                    )
                                }   
                                <Tooltip target=".export-buttons>button" position="bottom" />
                            </div>
                        </>}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Sensors;
