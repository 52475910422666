import { useEffect, useState } from "react";
import { Link as LinkT } from "react-router-dom";
import Layout from "./Layout";
import { Skeleton } from "primereact/skeleton";
import { getApiExperiencesOfPointOfInterest } from "../../../services/apiServices";
import { Carousel as CarouselPR } from "primereact/carousel";

const Carousel = ({ pointOfInterest, pointofinterestId }) => {
    const [experiences, setExperiences] = useState(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiExperiencesOfPointOfInterest(pointofinterestId);
            setIsFetching(response.success === true && response.error === false ? false : true);
            setExperiences(response.data.data);
        };

        fetchData();
    }, []);

    const poiTemplate = (item) => {
        return (
            <div className="w-full px-1" key={item.id}>
                <LinkT target="_blank" to={`/experience/${item.id}`}>
                    {/* {experience?.images?.[0]?.url !== undefined ? (
                            <img
                                className="object-cover w-full h-48 rounded-lg"
                                src={`${process.env.REACT_APP_STORAGE}/${experience?.images[0]?.url}`}
                                alt={experience.title}
                            />
                        ) : (
                            <Skeleton className="w-full" height="192px"></Skeleton>
                        )} */}
                    <img
                        className="object-cover w-full h-48 rounded-lg"
                        src={
                            item?.images?.[0]?.url
                                ? `${process.env.REACT_APP_STORAGE}/${item?.images[0]?.url}`
                                : "/default/default-image.png"
                        }
                        alt={item.title ? item.title : "No title"}
                    />
                </LinkT>
                <div className="w-full h-full duration-300 ease-in-out hover:cursor-pointer">
                    <div className="py-3">
                        <h1 className="m-0 text-base font-semibold">{item.title}</h1>
                        {/* <h3 className="m-0 text-base font-light text-neutral-500">#{experience.tag}</h3> */}
                    </div>
                </div>
            </div>
        );
    };

    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: "768px",
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: "560px",
            numVisible: 1,
            numScroll: 1,
        },
    ];

    return (
        <Layout title="Experiențe">
            {pointOfInterest && (
                <div className="py-3" id="submenuCarouselExp">
                    {isFetching ? (
                        <Skeleton width="w-full" height="10rem"></Skeleton>
                    ) : experiences.length === 0 ? (
                        <h3>Nici un punct de interes asociat acestei experiente</h3>
                    ) : (
                        <div>
                            {
                                <CarouselPR
                                    value={experiences}
                                    itemTemplate={poiTemplate}
                                    numVisible={2}
                                    numScroll={1}
                                    autoplayInterval={experiences.length === 1 ? null : 10000}
                                    circular={true}
                                    responsiveOptions={responsiveOptions}></CarouselPR>
                            }
                        </div>
                    )}
                </div>
            )}
        </Layout>
    );
};

export default Carousel;
