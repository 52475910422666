import { scroller } from "react-scroll";

const Submenu = ({ submenuType, pointOfInterest }) => {
    const scrollToTop = () => {
        scroller.scrollTo("submenuTopPoi", {
            duration: 500, // Durata efectului de scroll (în milisecunde)
            smooth: true, // Scrolare lină
            offset: -100, // Deplasare în sus pentru a nu acoperi conținutul elementului
        });
    };
    const scrollToDetail = () => {
        scroller.scrollTo("submenuDetailPoi", {
            duration: 500, // Durata efectului de scroll (în milisecunde)
            smooth: true, // Scrolare lină
            offset: -140, // Deplasare în sus pentru a nu acoperi conținutul elementului
        });
    };
    const scrollToPOI = () => {
        scroller.scrollTo("submenuCarouselExp", {
            duration: 500, // Durata efectului de scroll (în milisecunde)
            smooth: true, // Scrolare lină
            offset: -140, // Deplasare în sus pentru a nu acoperi conținutul elementului
        });
    };

    return (
        <div className="w-full bg-white" id="galleryPoi">
            <div className="container">
                <div className="flex flex-col items-start justify-center py-3 space-y-3 lg:items-center lg:justify-between lg:flex-row lg:space-y-0">
                    <div className="flex flex-col space-x-0 space-y-3 font-bold sm:space-x-7 text-slate-500 text-md sm:flex-row sm:space-y-0">
                        <div
                            onClick={scrollToTop}
                            className="cursor-pointer flex flex-row items-center pr-4 space-x-2 border-r border-transparent sm:border-slate-400 text-[#7d93b2]">
                            <i className="gmui gmui-gallery" style={{ fontSize: "18px" }}></i>
                            <span>Top</span>
                        </div>
                        <div
                            onClick={scrollToDetail}
                            className="cursor-pointer flex flex-row items-center pr-8 space-x-1 border-r border-transparent sm:border-slate-400 text-[#7d93b2]">
                            <i className="gmui gmui-info" style={{ fontSize: "18px" }}></i>
                            <span>Detalii</span>
                        </div>
                        <div onClick={scrollToPOI} className="flex flex-row items-center pr-8 space-x-2 text-[#7d93b2]">
                            <i className="cursor-pointer gmui gmui-image-view" style={{ fontSize: "18px" }}></i>
                            <span>{submenuType}</span>
                        </div>
                    </div>
                    {/* <button
                        onClick={() => do_favorite(experience?.id)}
                        className="flex items-center space-x-2 bg-[#425998] py-1.5 px-3 rounded-lg">
                        {userData?.user ? (
                            userData.user.favorites.includes(experience?.id) ? (
                                <>
                                    <FavoriteBorderIcon sx={{ color: "red" }} fontSize="small" />
                                    <span className="font-semibold text-white">Elimina Favorit</span>
                                </>
                            ) : (
                                <>
                                    <FavoriteBorderIcon sx={{ color: "rgb(94,203,94)" }} fontSize="small" />
                                    <span className="font-semibold text-white">Adauga Favorit</span>
                                </>
                            )
                        ) : (
                            <>
                                <FavoriteBorderIcon fontSize="small" />
                                <span>Adauga Favorit</span>
                            </>
                        )}
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default Submenu;
