import React, { useEffect, useState } from "react";

import Header from "../Header";
import Place from "./Place";
import Map from "./Map";

import SearchFilters from "../search/SearchFilters";
import { useSelector, useDispatch } from "react-redux";

import { listActions } from "../../slice/map/list-slice";
import { boundsActions } from "../../slice/map/bounds-slice";
import PlacesSkeleton from "../Skeleton/PlacesSkeleton";
import { useLocation } from "react-router-dom";

import "./style.css";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function MapComp() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    // Extrage parametrii
    const paramsObj = {};
    searchParams.forEach((value, key) => {
        paramsObj[key] = value;
    });

    const keywordFilterRedux = useSelector((state) => state.list.keywordFilter);
    const pois = useSelector((state) => state.bounds.pointsOfInterestState);
    const poiFetch = useSelector((state) => state.bounds.isFetchingPoi);
    const poiError = useSelector((state) => state.bounds.isErrorPoi);

    // const countPois = useSelector((state) => state.bounds.countPOI);

    const experiences = useSelector((state) => state.bounds.experiencesState);
    const expFetch = useSelector((state) => state.bounds.isFetchingExp);
    const expError = useSelector((state) => state.bounds.isErrorExp);

    // const countExp = useSelector((state) => state.bounds.countExperiences);
    const togglePOIEXP = useSelector((state) => state.list.togglePOIExp);

    const [favoriteExpIds, setFavoriteExpIds] = useState([]);
    const [filterStatus, setFilterStatus] = useState(false);

    const dispatch = useDispatch();
    const userData = getUserData();

    useEffect(() => {
        if ("tags" in paramsObj) {
            dispatch(boundsActions.getTagsId(paramsObj.tags.split(",").map((tag) => parseInt(tag))));
            setFilterStatus(true);
        } else if ("keyword" in paramsObj) {
            dispatch(listActions.updateKeywordFilter(paramsObj.keyword));
            setFilterStatus(true);
        }
    }, []);

    useEffect(() => {
        if (userData !== null) {
            const favoriteExpIdsTemp = userData.user.favorites.map((experience) => experience.id);
            setFavoriteExpIds(favoriteExpIdsTemp);
        }
    }, []);

    function LoadingPlaces({ counter, error, fetching, values, valueString }) {
        if (error) return <p>Datele nu au putut fi încărcate</p>;
        else if (fetching)
            return Array(10)
                .fill(1)
                .map((_, index) => <PlacesSkeleton key={index} />);
        else if (counter === 0) return <p className="mt-4">Nu s-a găsit nimic conform criterilor de căutare.</p>;
        else if (counter > 0)
            return values.map((place, index) => <Place place={place} key={index} linkTo={valueString} favExpIds={favoriteExpIds} />);
    }

    return (
        <>
            <div className="relative h-full">
                <Header />
                <div className="h-full grow">
                    <div className="relative h-full">
                        <div className="h-full p-0 col-sm-6">
                            <div className="h-full listing-item-container init-grid-items fl-wrap" id="lisfw">
                                <div className="container h-full">
                                    <div className="row nopadding">
                                        <div className="list-main-wrap-header fl-wrap anim_clw ">
                                            <div className="container headerfilterContainer">
                                                <div className="list-main-wrap-title col-sm-12">
                                                    <h2>
                                                        {togglePOIEXP ? experiences.length : pois.length}{" "}
                                                        {togglePOIEXP ? "Experiențe" : "Puncte de interes"}
                                                        {keywordFilterRedux && `. Cuvânt cheie: ${keywordFilterRedux}`}
                                                    </h2>
                                                </div>
                                                <div className="list-main-wrap-opt col-sm-12">
                                                    <div
                                                        className="price-opt"
                                                        onClick={() => {
                                                            dispatch(listActions.togglePoiExp());
                                                            dispatch(boundsActions.updateMapState());
                                                        }}>
                                                        <div className="price-opt-title">
                                                            <i className="gmui gmui-map-marker filterpin"></i>
                                                            {togglePOIEXP ? "Punctele de interes" : "Experiențele"}
                                                        </div>
                                                    </div>

                                                    <SearchFilters filterStatus={filterStatus} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h-full p-0 col-md-12 listing-container">
                                        {togglePOIEXP ? (
                                            <LoadingPlaces
                                                counter={experiences.length}
                                                error={expError}
                                                fetching={expFetch}
                                                values={experiences}
                                                valueString={"experience"}
                                            />
                                        ) : (
                                            <LoadingPlaces
                                                counter={pois.length}
                                                error={poiError}
                                                fetching={poiFetch}
                                                values={pois}
                                                valueString={"pointofinterest"}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-0 col-sm-6 mapcontainer">
                            <Map />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MapComp;
