import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";

import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import { getApiTags, getApiExperienceCategories, getApiPointOfInterestOfUser } from "../../services/apiServices";

import "./style.css";
import DashboardMenu from "../DashboardMenu";

// se i-au datele cand utilizatorul este logat
const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
function format_date(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
}

function CreateExperience() {
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const toast = useRef(null);
    let userData = getUserData();

    const [pointsOfInterest, setPointsOfInterest] = useState(null);
    const [tags, setTags] = useState(null);
    const [experienceCategories, setExperienceCategories] = useState(null);

    const [isFetchingPointsOfInterest, setIsFetchingPointsOfInterest] = useState(true);
    const [isFetchingTags, setIsFetchingTags] = useState(true);
    const [isFetchingExperienceCategories, setIsFetchingExperienceCategories] = useState(true);

    const [theImages, setTheImages] = useState(null);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiTags();
            setIsFetchingTags(response.success === true && response.error === false ? false : true);
            setTags(response.data);

            const response1 = await getApiExperienceCategories();
            setIsFetchingExperienceCategories(response1.success === true && response1.error === false ? false : true);
            setExperienceCategories(response1.data);

            const response2 = await getApiPointOfInterestOfUser(userData?.user?.id, userData?.token);
            setIsFetchingPointsOfInterest(response2.success === true && response2.error === false ? false : true);
            setPointsOfInterest(response2.data);
        };

        fetchData();
    }, []);

    // folosit pentru afisarea mesajelor in suprapunere - toast
    const showSuccess = (detailValue) => {
        toast.current.show({ severity: "success", summary: "Informația a fost trimisă cu succes", detail: detailValue, life: 3000 });
    };

    const showError = (detailValue) => {
        toast.current.show({
            severity: "error",
            summary: "Informația nu a putut fi trimisă către server",
            detail: detailValue,
            life: 3000,
        });
    };

    // https://formik.org/docs/overview
    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            location: "",
            score: 0,
            has_ar: false,
            has_vuforia: false,
            ar_prefab: "",
            status: "in_progress",
            starting_date: null,
            finishing_date: null,
            active: true,
            tags_ids: [],
            experience_category_id: 1,
            point_of_intrest: [],
            images_upload: [],
            youtube_url: "",
            facebook_url: "",
            website_url: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.title) {
                errors.title = "Titlul este necesar.";
            }

            if (!data.description) {
                errors.description = "Descrierea este necesară.";
            } else if (data.description.length > 10000) {
                errors.description = "Ai atins maximul de caractere.";
            } else if (data.description.length < 10) {
                errors.description = `O descriere minimă de 200 caractere este necesară. Mai ai de adaugat ${
                    1 - data.description.length
                } caractere.`;
            }

            if (!data.location) {
                errors.location = "Locația este necesară.";
            }

            if (!data.starting_date) {
                errors.starting_date = "Data de începere este necesară.";
            }

            if (!data.finishing_date) {
                errors.finishing_date = "Data de sfârșire este necesară";
            }

            if (data.starting_date && data.finishing_date) {
                if (data.starting_date.getTime() > data.finishing_date.getTime()) {
                    errors.starting_date = "Data de începere trebuie să fie mai mică sau aceeasi față de data de sfârșire.";
                }
            }

            // if (!data.status) {
            //     errors.status = "Statusul este necesar.";
            // }

            return errors;
        },
        // 419 - https://aimeos.org/help/viewtopic.php?f=15&t=3753&start=0
        onSubmit: (data) => {
            let idsTags = [];
            let idsPointsOfInterest = [];

            data.tags_ids.forEach((exp) => {
                idsTags.push(exp.id);
            });

            data.point_of_intrest.forEach((exp) => {
                idsPointsOfInterest.push(exp.id);
            });

            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL + "/web/experiences",
                headers: {
                    Authorization: "Bearer " + userData.token.split("|")[1],
                },
                data: {
                    ...data,
                    points_of_interest_ids: idsPointsOfInterest,
                    start_date: format_date(data.starting_date),
                    end_date: format_date(data.finishing_date),
                    tags_ids: idsTags,
                    created_by_id: userData.user.id,
                },
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            })
                .then((response) => {
                    formik.resetForm();
                    showSuccess(response?.data?.message);

                    if (redirect) navigate(`/experience/${response?.data?.data?.id}`);
                })
                .catch((error) => {
                    if (error.response) {
                        showError(error.response.status);
                    } else if (error.request) {
                        showError(error.request);
                    } else {
                        showError(error.message);
                    }
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const customBase64Uploader = async (event) => {
        let images = event.files;
        let temp = [];

        Object.keys(images).forEach((image) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(images[image]);
            fileReader.onload = () => {
                temp.push({
                    title: images[image].name,
                    image: fileReader.result,
                });
            };
        });

        console.log(images);

        formik.handleChange({
            target: { name: "images_upload", value: temp },
        });
    };

    const removeImage = (_id) => {
        const _theImages = theImages;
        const _theImage = _theImages.findIndex((x) => x.id === _id);
        var __theImages = [...theImages];
        delete __theImages[_theImage];
        __theImages = __theImages.filter((x) => x);

        console.log(__theImages);
        setTheImages(__theImages);
    };

    const resetFileInput = () => {
        inputRef.current.clear();
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="bg-[rgb(239,243,248)] min-h-full">
                <Header />
                <div className="container box-border py-8 mx-auto mt-10 bg-white rounded-lg newtheme ">
                    <DashboardMenu />
                    <div className=" col-md-9 col-sm-12">
                        <form onSubmit={formik.handleSubmit} className="space-y-6">
                            <div className="dashboard-title fl-wrap">
                                <h3>Adaugă o Experiență</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <label>
                                                Denumire <i className="gmui gmui-briefcase"></i>
                                            </label>

                                            <InputText
                                                id="title"
                                                name="title"
                                                value={formik.values.title}
                                                onChange={formik.handleChange}
                                                autoFocus
                                                className={classNames("w-full", { "p-invalid": isFormFieldValid("title") })}
                                            />
                                            {getFormErrorMessage("title")}
                                        </div>
                                        <div className="flex items-center justify-center mt-2 col-md-2">
                                            <div className="row">
                                                <div>
                                                    <Checkbox
                                                        inputId="active"
                                                        name="active"
                                                        checked={formik.values.active}
                                                        onChange={formik.handleChange}
                                                        className={classNames({
                                                            "p-invalid newtheme-checkbox": isFormFieldValid("active"),
                                                        })}
                                                    />
                                                    <label
                                                        htmlFor="active"
                                                        className={classNames("newtheme-checkbox-label", {
                                                            "p-error": isFormFieldValid("active"),
                                                        })}>
                                                        Activ
                                                    </label>
                                                    {getFormErrorMessage("active")}
                                                </div>
                                            </div>
                                            {/* <div className="mt-2 row">
                                                    <div>
                                                        <Checkbox
                                                            inputId="visible"
                                                            name="visible"
                                                            checked={formik.values.visible}
                                                            onChange={formik.handleChange}
                                                            className={classNames({
                                                                "p-invalid newtheme-checkbox": isFormFieldValid("visible"),
                                                            })}
                                                        />
                                                        <label
                                                            htmlFor="visible"
                                                            className={classNames("newtheme-checkbox-label", {
                                                                "p-error": isFormFieldValid("visible"),
                                                            })}>
                                                            Vizibil
                                                        </label>
                                                        {getFormErrorMessage("visible")}
                                                    </div>
                                                </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="description" className={classNames({ "p-error": isFormFieldValid("description") })}>
                                            Descriere
                                        </label>

                                        <InputTextarea
                                            rows={5}
                                            cols={30}
                                            id="description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            className={classNames("w-full mb-3", { "p-invalid": isFormFieldValid("description") })}
                                        />
                                    </div>
                                    {getFormErrorMessage("description")}
                                </div>
                                <div className="row">
                                    {!isFetchingTags && (
                                        <div className="col-md-12">
                                            <label>Cuvinte cheie</label>

                                            <div className="relative">
                                                {" "}
                                                <MultiSelect
                                                    id="tags_ids"
                                                    name="tags_ids"
                                                    value={formik.values.tags_ids}
                                                    onChange={formik.handleChange}
                                                    options={tags}
                                                    optionLabel="tag"
                                                    className="w-full"
                                                />
                                                <i className="absolute transform gmui gmui-key top-3.5 left-3"></i>
                                            </div>
                                            {getFormErrorMessage("tags")}
                                        </div>
                                    )}
                                </div>
                                <div className="mt-2 row">
                                    <div className="col-md-6">
                                        <label
                                            htmlFor="starting_date"
                                            className={classNames({ "p-error": isFormFieldValid("starting_date") })}>
                                            Dată începere*
                                        </label>
                                        {getFormErrorMessage("starting_date")}
                                        <div className="relative">
                                            <Calendar
                                                id="starting_date"
                                                name="starting_date"
                                                value={formik.values.starting_date}
                                                onChange={formik.handleChange}
                                                dateFormat="dd/mm/yy"
                                                mask="99/99/9999"
                                                // showIcon

                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-calendar top-3.5 left-3 z-10"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label
                                            htmlFor="finishing_date"
                                            className={classNames({ "p-error": isFormFieldValid("finishing_date") })}>
                                            Dată sfarșire*
                                        </label>
                                        {getFormErrorMessage("finishing_date")}
                                        <div className="relative">
                                            <Calendar
                                                id="finishing_date"
                                                name="finishing_date"
                                                value={formik.values.finishing_date}
                                                onChange={formik.handleChange}
                                                dateFormat="dd/mm/yy"
                                                mask="99/99/9999"
                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-calendar top-3.5 left-3 z-10"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Locatie</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label htmlFor="location" className={classNames({ "p-error": isFormFieldValid("location") })}>
                                                Adresa
                                                <i className="gmui gmui-map-marker"></i>
                                            </label>

                                            <InputText
                                                id="location"
                                                name="location"
                                                value={formik.values.location}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full positionField", {
                                                    "p-invalid": isFormFieldValid("address"),
                                                })}
                                            />
                                            {getFormErrorMessage("location")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Imagini</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="row">
                                    <label>Imagini</label>
                                    <div className="add-list-media-wrap">
                                        <div className="listsearch-input-item fl-wrap">
                                            <div className="fuzone">
                                                <FileUpload
                                                    ref={inputRef}
                                                    customUpload
                                                    uploadHandler={customBase64Uploader}
                                                    auto
                                                    id="images_upload"
                                                    name="images_upload"
                                                    multiple
                                                    accept="image/*"
                                                    emptyTemplate={
                                                        <div className="fu-text">
                                                            <span>
                                                                <i className="fa fal-images gmui gmui-images"></i> Drag-and-drop imagini
                                                                aici
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                                {theImages?.length > 0 && (
                                                    <div style={{ padding: "0 10px" }}>
                                                        {theImages.map(function (object, i) {
                                                            return (
                                                                <div className="float-left mb-6 mr-1" key={i} style={{ maxWidth: 250 }}>
                                                                    <img
                                                                        className="mt-6 mb-1"
                                                                        style={{ display: "inline-block" }}
                                                                        src={"http://dev2.smartsense.ro/storage/" + object.url}></img>
                                                                    <span
                                                                        onClick={() => removeImage(object.id)}
                                                                        className="text-base color2-bg p-button p-component p-button-raised p-button-secondary p-button-text p-button-sm">
                                                                        Elimina
                                                                    </span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Social</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>URL YouTube</label>
                                        {getFormErrorMessage("youtube_url")}
                                        <div className="relative">
                                            {" "}
                                            {/* Utilizăm clasa "relative" pentru a poziționa iconița */}
                                            <InputText
                                                id="youtube_url"
                                                name="youtube_url"
                                                value={formik.values.youtube_url}
                                                onChange={formik.handleChange}
                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-youtube top-3.5 left-3"></i>
                                        </div>
                                    </div>
                                    <div className="my-3 col-sm-12">
                                        <label>Facebook Url</label>
                                        {getFormErrorMessage("facebook_url")}
                                        <div className="relative">
                                            <InputText
                                                id="facebook_url"
                                                name="facebook_url"
                                                value={formik.values.facebook_url}
                                                onChange={formik.handleChange}
                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-facebook top-3.5 left-3"></i>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <label>Website Url</label>
                                        {getFormErrorMessage("website_url")}
                                        <div className="relative">
                                            <InputText
                                                id="website_url"
                                                name="website_url"
                                                value={formik.values.website_url}
                                                onChange={formik.handleChange}
                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-website top-3.5 left-3"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Puncte de interes</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        {!isFetchingPointsOfInterest && (
                                            <div className="col-md-12 col-sm-12">
                                                <label>
                                                    Asociază puncte de interes la experiențe:{" "}
                                                    <i className="gmui gmui-location customKey"></i>
                                                </label>
                                                <div className="listsearch-input-item">
                                                    <MultiSelect
                                                        id="point_of_intrest"
                                                        name="point_of_intrest"
                                                        value={formik.values.point_of_intrest}
                                                        onChange={formik.handleChange}
                                                        options={pointsOfInterest}
                                                        optionLabel="title"
                                                        className="w-full"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="flex justify-around pt-4">
                                    <Button
                                        type="submit"
                                        fontSize="small"
                                        onClick={() => setRedirect(true)}
                                        label="Adaugă si vizualizează"
                                        className="back-tofilters color2-bg custom-scroll-link logout_btn"
                                    />

                                    <Button
                                        onClick={resetFileInput}
                                        type="submit"
                                        label="Adaugă experieță"
                                        className="back-tofilters color2-bg custom-scroll-link logout_btn"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default CreateExperience;
