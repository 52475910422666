import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ItemListing from "./ItemListing";
import React, { useEffect, useState } from "react";
import { getApiExperiences } from "../../services/apiServices";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 2000 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 2000, min: 1300 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1300, min: 900 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 900, min: 0 },
        items: 1,
    },
};

const CustomDot = ({ active, onClick }) => {
    return (
        <button
            style={{
                backgroundColor: active ? "#007aff" : "#ced4da", // Customize active and inactive dot colors
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                border: "none",
                margin: "0 5px",
                cursor: "pointer",
            }}
            onClick={() => onClick()}
        />
    );
};

const LatestListing = () => {
    const [experiences, setExperiences] = useState([]);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiExperiences();
            setIsFetching(response.success === true && response.error === false ? false : true);
            // setExperiences(response.data.data.slice(0, 20));
            const array = response.data.data;
            const primele20 = array.slice(0, 12);
            setExperiences(primele20);
        };

        fetchData();
    }, []);

    return (
        <div className="my-10">
            <div className="max-w-[570px] mx-auto">
                <h1 className="text-4xl font-bold text-center my-7 ">Ultimele experiențe adaugate</h1>
                <div className="w-16 rounded-full h-1 bg-[#4DB7FE] mx-auto" />
                <p className="mt-3 mb-16 text-center">
                    Explorează Delta Dunării cu Smartsense! Aventuri unice în mijlocul naturii te așteaptă. Bucură-te de o escapadă
                    memorabilă!
                </p>
            </div>
            {!isFetching ? (
                <Carousel showDots customDot={<CustomDot />} responsive={responsive}>
                    {experiences.map((experience) => (
                        <ItemListing key={experience.id} experience={experience} />
                    ))}
                </Carousel>
            ) : (
                <h1>Se incarca</h1>
            )}
        </div>
    );
};

export default LatestListing;
