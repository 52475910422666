import HeaderExp from "./view/Header";
import Submenu from "./view/Submenu";
import Gallery from "./view/Gallery";
import Description from "./view/Description";

import { useParams } from "react-router-dom";
import { getApiExperience, getApiReviewsOfExperience } from "../../services/apiServices";
import React, { useEffect, useState } from "react";
import Carousel from "./view/Carousel";
import Reviews from "./view/Reviews";
import HostedBy from "./view/HostedBy";

import AddReview from "./view/AddReview";
import Footer from "../Footer";
import Header from "../Header";
import MiniMapExperience from "../map/MiniMapExperience";
import Layout from "./view/Layout";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const ExperienceView = () => {
    const { experienceId } = useParams();
    const [userData, setUserData] = useState(getUserData());
    const [experience, setExperience] = useState(null);
    const [isFetchingExperience, setIsFetchingExperience] = useState(true);
    const [reviews, setReviews] = useState(null);
    const [isFetchingReviews, setIsFetchingReviews] = useState(true);

    const fetchData = async () => {
        const response = await getApiExperience(experienceId);
        setIsFetchingExperience(response.success === true && response.error === false ? false : true);
        setExperience(response.data.data);

        // const response1 = await getApiExperiencesOfUser(experienceId, userData.token.split("|"));
        // setExperiencesUser(response1.success === true && response1.error === false ? false : true);
        // setIsFetchingExperiencesUser(response1.data.data);

        console.log(response.data.data.average_review);

        if (response.data.data) {
            const response2 = await getApiReviewsOfExperience(response.data.data.id);
            setIsFetchingReviews(response2.success === true && response2.error === false ? false : true);
            setReviews(response2.data.data);
        }
    };

    const fetchDataExp = async () => {
        const response = await getApiExperience(experienceId);
        setIsFetchingExperience(response.success === true && response.error === false ? false : true);
        setExperience(response.data.data);
    };

    const handleChildButtonClick = () => {
        fetchData();
        setTimeout(() => {
            fetchDataExp();
        }, 1000);
    };

    const handleChildButtonClickDelete = () => {
        fetchData();
        setTimeout(() => {
            fetchDataExp();
        }, 1000);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="bg-[#f6f6f6] min-h-full">
            <Header />
            <HeaderExp
                experience={experience}
                isFetchingExperience={isFetchingExperience}
                reviews={reviews}
                isFetchingReviews={isFetchingReviews}
            />
            {!isFetchingExperience && <Submenu submenuType="Puncte de interes" experience={experience} userData={userData} />}

            <div className="container py-4">
                {!isFetchingExperience && (
                    <div className="flex flex-col space-x-0 space-y-5 md:space-x-5 md:space-y-0 md:flex-row">
                        <div className="flex flex-col flex-grow space-y-5 overflow-auto basis-2/3">
                            <Gallery experience={experience} isFetchingExperience={isFetchingExperience} />
                            <Description experience={experience} />
                            <Carousel experience={experience} />
                            <Reviews
                                experience={experience}
                                reviews={reviews}
                                isFetching={isFetchingReviews}
                                onButtonPressDelete={handleChildButtonClickDelete}
                            />
                            <AddReview experience={experience} userData={userData} onButtonPress={handleChildButtonClick} />
                            {experience.points_of_interest.length !== 0 && (
                                <Layout title="Harta punctelor de interes">
                                    <MiniMapExperience experience_id={experience.id} />
                                </Layout>
                            )}
                        </div>
                        <div className="flex flex-col space-y-5 basis-1/3">
                            <HostedBy username={experience?.created_by} />
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default ExperienceView;
