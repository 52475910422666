import React from "react";
import { Skeleton } from "primereact/skeleton";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Gallery = ({ pointOfInterest, isFetchingPointOfInterest }) => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 1024, min: 768 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const CustomLeftArrow = ({ onClick }) => (
        <button onClick={onClick} className="absolute p-2 text-white transform -translate-y-1/2 bg-gray-600 rounded-full left-3 top-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                    fillRule="evenodd"
                    d="M15.707 4.293a1 1 0 010 1.414L9.414 12l6.293 6.293a1 1 0 01-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 0z"
                    clipRule="evenodd"
                />
            </svg>
        </button>
    );

    const CustomRightArrow = ({ onClick }) => (
        <button onClick={onClick} className="absolute p-2 text-white transform -translate-y-1/2 bg-gray-600 rounded-full right-3 top-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                    fillRule="evenodd"
                    d="M8.293 4.293a1 1 0 011.414 0l7 7a1 1 0 010 1.414l-7 7a1 1 0 11-1.414-1.414L14.586 12 8.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
            </svg>
        </button>
    );

    function CarouselImages(props) {
        const shouldShowArrowsAndDots = props.images.length > 1;
        return (
            <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={shouldShowArrowsAndDots}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                arrows={shouldShowArrowsAndDots}
                customLeftArrow={shouldShowArrowsAndDots ? <CustomLeftArrow /> : null}
                customRightArrow={shouldShowArrowsAndDots ? <CustomRightArrow /> : null}>
                {props.images.map((image, index) => (
                    <img
                        key={index}
                        className="object-cover w-full h-[60vh] min-h-[20rem] max-h-[42rem] rounded-lg"
                        src={`${process.env.REACT_APP_STORAGE}/${image.url}`}
                        alt={`Gallery image ${index + 1}`}
                    />
                ))}
            </Carousel>
        );
    }

    return (
        <div id="submenuTopPoi">
            {!isFetchingPointOfInterest && pointOfInterest?.images !== undefined ? (
                pointOfInterest?.images.length > 0 ? (
                    <CarouselImages images={pointOfInterest?.images} />
                ) : (
                    <img
                        className="object-cover w-[100%] h-[60vh] min-h-[20rem] max-h-[42rem] rounded-lg"
                        src="/default/default-image.png"
                        alt=""
                    />
                )
            ) : (
                <Skeleton width="w-full" height="35rem"></Skeleton>
            )}
        </div>
    );
};

export default Gallery;
