const Header = ({ pointOfInterest, isFetchingPointOfInterest }) => {
    return (
        <div className="relative w-full">
            {!isFetchingPointOfInterest && pointOfInterest.images.length !== 0 ? (
                <img
                    className="object-cover w-full min-h-[450px] max-h-[560px] h-1/3"
                    src={`${process.env.REACT_APP_STORAGE}/${pointOfInterest.images[0].url}`}
                    alt={pointOfInterest.images[0].title}
                />
            ) : (
                <img className="object-cover w-full min-h-[450px] max-h-[560px] h-1/3" src="/default/default-image.png" alt="" />
            )}

            <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t to-[rgba(6,27,65,0)] from-[rgba(6,27,65,0.95)] flex flex-col justify-end">
                <div className="container flex flex-col text-white">
                    <div className="w-12 h-px my-4 bg-white rounded-full" />
                    <div className="flex flex-col justify-between lg:flex-row">
                        <div className="flex flex-col">
                            {!isFetchingPointOfInterest ? (
                                <h1 className="text-4xl font-bold text-white">{pointOfInterest.title}</h1>
                            ) : (
                                "Se incarca"
                            )}
                            <div className="flex flex-row justify-start space-x-3">
                                <div className="flex space-x-1.5">
                                    <i className="text-blue-500 gmui gmui-map-marker" style={{ fontSize: "16px" }}></i>
                                    {!isFetchingPointOfInterest ? <span>{pointOfInterest.address}</span> : "Se incarca"}
                                </div>
                                {/* <div className="flex space-x-1.5">
                                    <i className="text-blue-500 gmui gmui-phone" style={{ fontSize: "16px" }}></i>
                                    {!isFetchingExperience ? <span> +348194129412</span> : "Se incarca"}
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-px my-4 rounded-full bg-slate-600" />
                    <div className="flex flex-col justify-center my-6 space-y-5 lg:justify-between lg:flex-row lg:space-y-0">
                        <div className="flex flex-row items-center space-x-5">
                            {!isFetchingPointOfInterest && (
                                <div className="flex flex-row items-center space-x-2">
                                    <div
                                        style={{ backgroundColor: pointOfInterest.point_of_interest_category.color }}
                                        className={`rounded-full h-8 w-8 text-center`}>
                                        <img
                                            src={`${process.env.REACT_APP_STORAGE}/${pointOfInterest.point_of_interest_category.icon_url}`}
                                            alt={
                                                pointOfInterest?.point_of_interest_category?.title &&
                                                pointOfInterest?.point_of_interest_category?.title
                                            }
                                        />
                                    </div>
                                    <span>{pointOfInterest.point_of_interest_category.title}</span>
                                </div>
                            )}
                            {/* <div
                                className="flex items-center h-8 space-x-5 py-1.5 pl-2 pr-3 rounded-full bg-emerald-300"
                                style={{ boxShadow: "0px 0px 0px 4px rgba(255,255,255,0.2)" }}>
                                <i className=" gmui gmui-open-lock" style={{ fontSize: "16px" }}></i>
                                <span>Activ</span>
                            </div> */}
                        </div>
                        <div className="flex flex-row space-x-2">
                            {pointOfInterest?.tags.length > 0 ? (
                                pointOfInterest?.tags.map((tag) => (
                                    <a
                                        href={`/map?tags=${tag.id}`}
                                        key={tag.id}
                                        className="flex flex-row items-center space-x-2 text-white">
                                        <div style={{ backgroundColor: tag.color }} className="p-1.5 rounded-full ">
                                            {/* <img src={tag.icon_url} alt="" /> */}
                                            <img
                                                className="w-8 h-8"
                                                src={`${process.env.REACT_APP_STORAGE}/${tag.icon_url}`}
                                                alt={tag.tag}
                                            />
                                        </div>
                                        <span>{tag.tag}</span>
                                    </a>
                                ))
                            ) : (
                                <span className="h-11">Nu există tag-uri atașate acestei experiente</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
