import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "primereact/tooltip";
import { FileUpload } from "primereact/fileupload";

import {
    useGetTagsExperienceQuery,
    useGetUserOwnExperiencesQuery,
    useGetCategoriesPointOfIntrestQuery,
} from "../../services/smartsenseApi";
import "./style.css";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import DashboardMenu from "../DashboardMenu";

import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function CreatePointofinterest() {
    let userData = getUserData();
    const [userExperiences, setUserExperiences] = useState([]);
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);
    const toast = useRef(null);
    const [theImages, setTheImages] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [markers, setMarkers] = useState([]);
    const navigate = useNavigate();

    const showSuccess = (detailValue) => {
        toast.current.show({ severity: "success", summary: "Success Message", detail: detailValue, life: 3000 });
    };

    const showError = (detailValue) => {
        toast.current.show({ severity: "error", summary: "Error Message", detail: detailValue, life: 3000 });
    };

    const clear = () => {
        toast.current.clear();
    };

    const { data: tagsPOI, isFetching: isFetchingTags, error: errorTags } = useGetTagsExperienceQuery(); //used
    const {
        data: _userExperiences,
        isFetching: isFetchingExperiences,
        error: errorUserExperiences,
    } = useGetUserOwnExperiencesQuery(userData.user.id); //used
    const {
        data: categoriesPOI,
        isFetching: isFetchingCategoryExperiences,
        error: errorCategoryExperiences,
    } = useGetCategoriesPointOfIntrestQuery();

    useEffect(() => {
        if (!isFetchingTags) {
            setTags(tagsPOI?.data);
        }
    }, [isFetchingTags]);

    useEffect(() => {
        if (!isFetchingCategoryExperiences) {
            setCategories(categoriesPOI?.data);
        }
    }, [isFetchingCategoryExperiences]);

    useEffect(() => {
        if (!isFetchingExperiences) {
            setUserExperiences(_userExperiences?.data);
        }
    }, [isFetchingExperiences]);

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            address: "",
            phone_number: "",
            latitude: "",
            longitude: "",
            mesh_name: "-",
            visible: true,
            active: true,
            point_of_interest_category: "",
            images_upload: [],
            experiences: [],
            tags_ids: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.title) {
                errors.title = "Titlul este necesar.";
            }

            if (!data.description) {
                errors.description = "Descrierea este necesară.";
            }

            if (!data.address) {
                errors.address = "Adresa este necesară.";
            }

            if (!data.phone_number) {
                errors.phone_number = "Numărul de telefon e necesar.";
            }

            if (!data.latitude) {
                errors.latitude = "Plasați punctul de interest pe hartă.";
            }

            if (!data.longitude) {
                errors.longitude = "Plasați punctul de interest pe hartă.";
            }

            // if (!data.mesh_name) {
            //     errors.mesh_name = "Mesh name is required.";
            // }

            if (!data.point_of_interest_category) {
                errors.point_of_interest_category = "Categoria este necesară.";
            }

            // if (!data.tags_ids) {
            //     errors.tags_ids = "Tags are required.";
            // }

            // if (!data.mesh_name) {
            //     errors.mesh_name = "Mesh name is required.";
            // }

            // if (!data.visible) {
            //     errors.visible = "Visibility is required.";
            // }

            // if (!data.active) {
            //     errors.active = "Status is required.";
            // }

            return errors;
        },

        onSubmit: (data) => {
            var sendTags = [];
            var sendExperiences = [];

            console.log(data.experiences);
            data.experiences.map((exp, i) => {
                sendExperiences.push(exp.id);
            });
            data.tags.map((exp, i) => {
                sendTags.push(exp.id);
            });

            var sendData = {
                title: data.title,
                description: data.description,
                address: data.address,
                phone_number: data.phone_number,
                latitude: data.latitude,
                longitude: data.longitude,
                mesh_name: "-",
                visible: data.visible,
                active: data.active,
                category_id: data.point_of_interest_category.id,
                user_id: userData.user.id,
                images_upload: data.images_upload,
                experience_ids: sendExperiences,
                tag_ids: sendTags,
                type: "create",
            };
            // return;

            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL + "/web/points-of-interest",
                data: sendData,
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            })
                .then((response) => {
                    showSuccess(response?.data?.message);
                    formik.resetForm();
                    if (redirect) navigate(`/pointofinterest/${response?.data?.data?.id}`);
                })
                .catch((error) => {
                    if (error.response) {
                        showError(error.response.status);
                    } else if (error.request) {
                        showError(error.request);
                    } else {
                        showError(error.message);
                    }
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    function LocationMarker() {
        const [position, setPosition] = useState(null);

        const map = useMap();

        useEffect(() => {
            map.locate().on("locationfound", function (e) {
                setPosition(e.latlng);
                map.flyTo(e.latlng, "15");
            });
        }, [map]);

        return position === null ? null : null;
    }
    function AddMarkerOnClick() {
        const map = useMapEvents({
            click(e) {
                const newMarker = e.latlng;
                formik.values.latitude = newMarker.lat;
                formik.values.longitude = newMarker.lng;
                var testurl =
                    "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=RO&location=" +
                    newMarker.lng +
                    "," +
                    newMarker.lat;

                axios.get(testurl).then((response) => {
                    formik.values.address = response.data.address.LongLabel;
                    setMarkers([newMarker]);
                });
            },
        });

        return (
            <>
                {markers.map((marker, index) => (
                    <Marker
                        position={marker}
                        key={index}
                        icon={L.divIcon({
                            html: `
                            <svg style='fill:red' className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='FmdGoodIcon'><path d='M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'></path></svg>`,
                            className: "",
                            iconSize: [34, 45],
                            iconAnchor: [15, 15],
                        })}></Marker>
                ))}
            </>
        );
    }

    const customBase64Uploader = async (event) => {
        let images = event.files;
        let temp = [];

        Object.keys(images).forEach((image) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(images[image]);
            fileReader.onload = () => {
                temp.push({
                    title: images[image].name,
                    image: fileReader.result,
                });
            };
        });
        formik.handleChange({
            target: { name: "images_upload", value: temp },
        });
    };

    const removeImage = (_id) => {
        const _theImages = theImages;
        const _theImage = _theImages.findIndex((x) => x.id === _id);
        var __theImages = [...theImages];
        delete __theImages[_theImage];
        __theImages = __theImages.filter((x) => x);

        setTheImages(__theImages);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="bg-[rgb(239,243,248)] min-h-full">
                <Header />
                <div className="container box-border py-8 mx-auto mt-10 bg-white rounded-lg newtheme">
                    <DashboardMenu />
                    <div className="col-md-9 col-sm-12">
                        <form onSubmit={formik.handleSubmit} className="space-y-6">
                            <div className="dashboard-title fl-wrap">
                                <h3>Adaugă un Punct de interes</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <label>
                                                Denumire <i className="gmui gmui-briefcase"></i>
                                            </label>

                                            <InputText
                                                id="title"
                                                name="title"
                                                value={formik.values.title}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full", { "p-invalid": isFormFieldValid("title") })}
                                            />
                                            {getFormErrorMessage("title")}
                                        </div>
                                        <div className="mt-4 col-md-2">
                                            <div className="row">
                                                <div>
                                                    <Checkbox
                                                        inputId="active"
                                                        name="active"
                                                        checked={formik.values.active}
                                                        onChange={formik.handleChange}
                                                        className={classNames({
                                                            "p-invalid newtheme-checkbox": isFormFieldValid("active"),
                                                        })}
                                                    />
                                                    <label
                                                        htmlFor="active"
                                                        className={classNames("newtheme-checkbox-label", {
                                                            "p-error": isFormFieldValid("active"),
                                                        })}>
                                                        Activ
                                                    </label>
                                                    {getFormErrorMessage("active")}
                                                </div>
                                            </div>
                                            <div className="mt-2 row">
                                                <div>
                                                    <Checkbox
                                                        inputId="visible"
                                                        name="visible"
                                                        checked={formik.values.visible}
                                                        onChange={formik.handleChange}
                                                        className={classNames({
                                                            "p-invalid newtheme-checkbox": isFormFieldValid("visible"),
                                                        })}
                                                    />
                                                    <label
                                                        htmlFor="visible"
                                                        className={classNames("newtheme-checkbox-label", {
                                                            "p-error": isFormFieldValid("visible"),
                                                        })}>
                                                        Vizibil
                                                    </label>
                                                    {getFormErrorMessage("visible")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label
                                                htmlFor="description"
                                                className={classNames({ "p-error": isFormFieldValid("description") })}>
                                                Descriere
                                            </label>

                                            <InputTextarea
                                                rows={5}
                                                cols={30}
                                                id="description"
                                                name="description"
                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full mb-3", { "p-invalid": isFormFieldValid("description") })}
                                            />
                                            {getFormErrorMessage("description")}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Categorie</label>

                                            <div className="listsearch-input-item">
                                                <Dropdown
                                                    id="point_of_interest_category"
                                                    name="point_of_interest_category"
                                                    value={formik.values.point_of_interest_category}
                                                    onChange={(e) => {
                                                        formik.handleChange({
                                                            target: { name: "point_of_interest_category", value: e.value },
                                                        });
                                                    }}
                                                    options={categories}
                                                    optionLabel="title"
                                                    className="w-full chosen-select no-search-select nice-select"
                                                />
                                            </div>
                                            {getFormErrorMessage("point_of_interest_category")}
                                        </div>
                                        <div className="col-md-6">
                                            <label>
                                                Cuvinte cheie <i className="gmui gmui-key customKey"></i>
                                            </label>
                                            {getFormErrorMessage("tags")}
                                            <div className="listsearch-input-item">
                                                <MultiSelect
                                                    id="tags"
                                                    name="tags"
                                                    value={formik.values.tags}
                                                    onChange={formik.handleChange}
                                                    options={tags}
                                                    optionLabel="tag"
                                                    className="w-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Locatie / Contact</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="mb-3 map-container">
                                        <MapContainer
                                            center={[45.94316, 24.96676]}
                                            zoom={7}
                                            style={{ height: "500px" }}
                                            zoomControl={false}>
                                            <TileLayer
                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <LocationMarker />
                                            <AddMarkerOnClick />
                                        </MapContainer>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>
                                                Longitudine<i className="gmui gmui-long-arrow-alt-right"></i>{" "}
                                            </label>
                                            <Tooltip target=".positionField" />
                                            <InputText
                                                disabled
                                                tooltip="Locatia punctului de interes se obtine pozitionand pin-ul pe harta!"
                                                tooltipOptions={{ mouseTrack: true, showOnDisabled: true }}
                                                id="longitude"
                                                name="longitude"
                                                value={formik.values.longitude}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full positionField", {
                                                    "p-invalid": isFormFieldValid("longitude"),
                                                })}
                                            />
                                            {getFormErrorMessage("longitude")}
                                        </div>
                                        <div className="col-md-6">
                                            <label>
                                                Latitudine<i className="gmui gmui-long-arrow-alt-down"></i>{" "}
                                            </label>
                                            <Tooltip target=".positionField" />
                                            <InputText
                                                disabled
                                                tooltip="Locatia punctului de interes se obtine pozitionand pin-ul pe harta!"
                                                tooltipOptions={{ mouseTrack: true, showOnDisabled: true }}
                                                id="latitude"
                                                name="latitude"
                                                value={formik.values.latitude}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full positionField", {
                                                    "p-invalid": isFormFieldValid("latitude"),
                                                })}
                                            />
                                            {getFormErrorMessage("latitude")}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label>
                                                Addresa<i className="gmui gmui-map-marker"></i>
                                            </label>
                                            <Tooltip target=".positionField" />
                                            <InputText
                                                disabled
                                                tooltip="Locatia punctului de interes se obtine pozitionand pin-ul pe harta!"
                                                tooltipOptions={{ mouseTrack: true, showOnDisabled: true }}
                                                id="address"
                                                name="address"
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full positionField", { "p-invalid": isFormFieldValid("address") })}
                                            />
                                            {getFormErrorMessage("address")}
                                        </div>
                                        <div className="col-sm-6">
                                            <label>
                                                Telefon<i className="far fa-phone"></i>{" "}
                                            </label>

                                            <InputText
                                                id="phone_number"
                                                name="phone_number"
                                                value={formik.values.phone_number}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full", { "p-invalid": isFormFieldValid("phone_number") })}
                                            />
                                            {getFormErrorMessage("phone_number")}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label>Imagini</label>
                                        <div className="add-list-media-wrap">
                                            <div className="listsearch-input-item fl-wrap">
                                                <div className="fuzone">
                                                    <FileUpload
                                                        customUpload
                                                        onSelect={customBase64Uploader}
                                                        name="images_upload"
                                                        multiple
                                                        accept="image/*"
                                                        emptyTemplate={
                                                            <div className="fu-text">
                                                                <span>
                                                                    <i className="fa fal-images gmui gmui-images"></i> Drag-and-drop imagini
                                                                    aici
                                                                </span>
                                                            </div>
                                                        }
                                                    />
                                                    {theImages?.length > 0 && (
                                                        <div style={{ padding: "0 10px" }}>
                                                            {theImages.map(function (object, i) {
                                                                return (
                                                                    <div className="float-left mb-6 mr-1" key={i} style={{ maxWidth: 250 }}>
                                                                        <img
                                                                            className="mt-6 mb-1"
                                                                            style={{ display: "inline-block" }}
                                                                            src={"http://dev2.smartsense.ro/storage/" + object.url}></img>
                                                                        <span
                                                                            onClick={() => removeImage(object.id)}
                                                                            className="text-base color2-bg p-button p-component p-button-raised p-button-secondary p-button-text p-button-sm">
                                                                            Elimina
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Experiente</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <label>
                                                Asociază punctul de interes la experiențe: <i className="gmui gmui-location customKey"></i>
                                            </label>
                                            <div className="listsearch-input-item">
                                                <MultiSelect
                                                    id="experiences"
                                                    name="experiences"
                                                    onChange={formik.handleChange}
                                                    options={userExperiences}
                                                    // options={testExperiences}
                                                    value={formik.values.experiences}
                                                    // value={testSelectedExperiences}
                                                    optionLabel="title"
                                                    className="w-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="flex justify-around pt-4">
                                    <Button
                                        type="submit"
                                        fontSize="small"
                                        onClick={() => setRedirect(true)}
                                        label="Adaugă si vizualizează"
                                        className="back-tofilters color2-bg custom-scroll-link logout_btn"
                                    />

                                    <Button
                                        type="submit"
                                        label="Adaugă punct de interes"
                                        className="back-tofilters color2-bg custom-scroll-link logout_btn"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default CreatePointofinterest;
