import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApiTags } from "../../services/apiServices";
import { useNavigate } from "react-router-dom";

const Explore = () => {
    const [searchValue, setSearchValue] = useState("");
    const [isFetchingTags, setIsFetchingTags] = useState(true);

    const [tags, setTags] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiTags();
            setIsFetchingTags(response.success === true && response.error === false ? false : true);
            setTags(response.data);
        };

        fetchData();
    }, []);

    // pentru input search
    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            navigate(`/map?keyword=${searchValue}`);
        }
    };

    const doSearch = (event) => {
        navigate(`/map?keyword=${searchValue}`);
    };


    return (
        <div className="relative w-full overflow-hidden text-white">
            <img
                className="object-cover w-screen md:min-h-[855px] max-h-[855px] min-h-[1040px]"
                src="delta/headerhome.jpg"
                alt="Delta Dunarii"
            />
            <div className="absolute top-0 left-0 flex flex-col items-center pt-10 w-full h-full mx-auto bg-[rgba(0,0,0,0.4)]">
                <div className="w-12 rounded-full h-0.5 bg-[#4DB7FE] mb-8" />
                <h1 className="text-4xl font-bold text-center text-white">Exploreză cele mai frumoase zone și experiente</h1>
                <p className="mx-2 text-sm font-semibold">
                    Găsiți câteva dintre cele mai bune sfaturi din jurul orașului de la partenerii și prietenii noștri.
                </p>
                <div className=" mb-3 mt-5 bg-[rgba(212,212,212,0.4)] text-black rounded-lg p-2 relative">
                    <div className="flex flex-col w-full bg-white rounded-md md:flex-row">
                        <input
                            className="px-4 py-2.5 border-b shadow-none outline-none sm:rounded-l-md rounded-t-md sm:my-0 border-b-slate-400 sm:border-b-transparent "
                            type="text"
                            placeholder="Ce cauți?"
                            value={searchValue}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                        />

                        <button onClick={(e) => doSearch()} className="w-56  bg-[#384F95] mx-2 rounded-md text-white my-1 flex flex-row items-center justify-evenly">
                            <i className="text-lg gmui gmui-search"></i>
                            <span>Caută</span>
                        </button>
                    </div>
                </div>

                <div className="w-12 h-px my-4 bg-white rounded-full" />
                <p className="mx-2 text-sm font-semibold">Ești în cautare de ceva? Utilizați căutarea rapidă după tags</p>
                <div className="grid grid-cols-3 mx-2 mt-4 md:grid-cols-4 lg:grid-cols-8 gap-x-16 gap-y-4 lg:gap-y-0">
                    {!isFetchingTags &&
                        tags.map((tag) => (
                            <a href={`/map?tags=${tag.id}`} key={tag.id} className="flex flex-col items-center space-y-2">
                                <img
                                    className="object-cover h-14 w-14"
                                    src={`${process.env.REACT_APP_STORAGE}/${tag?.icon_url}`}
                                    alt={tag.tag}
                                />
                                <span className="font-semibold text-white">{tag.tag}</span>
                            </a>
                        ))}
                </div>
                <div className="mt-5 mb-2 text-center">
                    <h3 className="mb-2 text-lg font-bold text-white">Descopera mai multe</h3>
                    <Link to={"/map"} className="px-3 text-black bg-white py-1.5 rounded-lg">
                        Hartă
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Explore;
