import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Rating } from "primereact/rating";
import styled from "styled-components";
import axios from "axios";

const StyledRating = styled(Rating)`
    .pi-star-fill {
        color: #facc39 !important;
        font-size: 13px;
    }
    .pi-star {
        color: rgba(255, 255, 255, 0.4);
        font-size: 13px;
    }
`;

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const ItemListing = ({ experience }) => {
    const userData = getUserData();
    const divRef = useRef(null);

    const [showSecondDiv, setShowSecondDiv] = useState(false);

    const handleMouseEnter = () => {
        setShowSecondDiv(true);
    };

    const handleMouseLeave = () => {
        setShowSecondDiv(false);
    };

    let _userData = JSON.parse(JSON.stringify(userData));
    let favExperiencesIDs = [];

    if (_userData && _userData.user.favorites)
        Object.keys(_userData.user.favorites).forEach((favExperienceKey) => {
            if (
                typeof _userData.user.favorites[favExperienceKey] === "object" &&
                !Array.isArray(_userData.user.favorites[favExperienceKey]) &&
                _userData.user.favorites[favExperienceKey] !== null
            ) {
                favExperiencesIDs.push(_userData.user.favorites[favExperienceKey].id);
            } else favExperiencesIDs = _userData.user.favorites;
        });

    let favorite = () => {
        let stateFav = false;
        favExperiencesIDs &&
            favExperiencesIDs.forEach((id) => {
                if (id === experience.id) stateFav = true;
            });

        return stateFav;
    };

    const [toggleFavorite, setToggleFavorite] = useState(favorite());
    const [redirect, setRedirect] = useState(false);

    function redirectIfUserNotLoggedIn() {
        if (userData === null) setRedirect(true);
    }

    async function updateFavoriteExperience(experience) {
        if (!userData) alert("Pentru a putea adauga o experienta la favorite este necesar sa va inregistrati");

        let newData = favExperiencesIDs;
        const experienceId = experience.id;

        if (!newData.includes(experienceId)) {
            newData.push(experienceId);
        } else {
            newData.splice(newData.indexOf(experienceId), 1);
        }

        let _userData = JSON.parse(JSON.stringify(userData));
        let favExperiences = _userData.user.favorites;

        const i = favExperiences.findIndex((e) => e.id === experienceId);
        if (i > -1) {
            favExperiences.splice(i, 1);
        } else {
            favExperiences.push(experience);
        }
        _userData.user.favorites = favExperiences;

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `/web/users/${userData.user.id}/favorite-experiences`,
            data: { experiences_ids: newData },
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        })
            .then((response) => {
                setToggleFavorite(!toggleFavorite);
                localStorage.setItem("userData", JSON.stringify(_userData));
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log(error.message);
                }
            });
    }

    return (
        <div ref={divRef} className="relative mx-2 mb-16 text-white h-80">
            <img
                className="object-cover w-full h-full rounded-xl"
                src={
                    experience?.images[0]?.url
                        ? `${process.env.REACT_APP_STORAGE}/${experience.images[0].url}`
                        : "default/default-image.png"
                }
                alt={experience?.images[0]?.title ? experience.images[0]?.title : "default-image.png"}
            />

            <div className="absolute bottom-0 left-0 w-full h-64 bg-gradient-to-t to-[rgba(6,27,65,0)] from-[rgba(6,27,65,0.95)]"></div>

            <div className="absolute top-0 left-0 flex flex-col justify-between w-full h-full px-3 pt-4 text-white">
                {/* upper div */}
                <div
                    className="relative w-24 h-8 hover:cursor-pointer"
                    onClick={() => {
                        updateFavoriteExperience(experience);
                        redirectIfUserNotLoggedIn();
                    }}>
                    {toggleFavorite ? (
                        <div className="relative w-24 h-8 hover:cursor-pointer">
                            <div
                                className="absolute w-8 h-8 text-2xl text-center bg-blue-700 rounded-full"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>
                                <div className="gmui gmui-heart favoriteheart"></div>
                            </div>
                            {showSecondDiv && (
                                <div
                                    className="flex items-center justify-end w-24 h-8 px-3 bg-blue-700 rounded-full save-text"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
                                    Elimină
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="relative w-24 h-8 hover:cursor-pointer">
                            <div
                                className="absolute w-8 h-8 text-2xl text-center bg-blue-700 rounded-full favoriteheart2"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>
                                ♡
                            </div>
                            {showSecondDiv && (
                                <div
                                    className="flex items-center justify-end w-24 h-8 px-3 bg-blue-700 rounded-full save-text"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
                                    Adaugă
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {/* bottom div */}
                <div className="flex flex-col">
                    <div className="flex flex-row justify-between my-2">
                        <div>
                            <Link to={`/experience/${experience.id}`} className="mb-1 font-bold text-white">
                                {experience.title}
                            </Link>
                            <div className="flex flex-row items-center space-x-1">
                                <i className="text-lg gmui gmui-map-marker text-sky-400"></i>
                                <span className="text-sm">{experience.location}</span>
                            </div>
                        </div>
                        <div className="flex flex-row items-center space-x-3">
                            <span className="bg-[#384F95] px-2 py-1.5 text-white rounded-md">{experience.average_review}</span>
                            <div className="flex flex-col leading-4">
                                <StyledRating value={Math.floor(experience.average_review)} readOnly stars={5} cancel={false} />
                                {/* <span className="text-xs">{experience.score}</span> */}
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-px bg-black" />

                    <div className="flex flex-row justify-between my-2 space-x-3">
                        <div className="flex flex-row space-x-5">
                            {experience?.tags.length > 0 ? (
                                experience?.tags.map((tag) => (
                                    <a href={`/map?tags=${tag.id}`} key={tag.id} className="flex flex-col items-center text-white ">
                                        <div style={{ backgroundColor: tag.color }} className="p-1.5 rounded-full ">
                                            {/* <img src={tag.icon_url} alt="" /> */}
                                            <img
                                                className="w-8 h-8"
                                                src={`${process.env.REACT_APP_STORAGE}/${tag.icon_url}`}
                                                alt={tag.tag}
                                            />
                                        </div>
                                        <span>{tag.tag}</span>
                                    </a>
                                ))
                            ) : (
                                <span className="h-11">Nu există tag-uri atașate acestei experiente</span>
                            )}
                        </div>
                        <div className="flex flex-col items-center">
                            <img
                                className="object-cover w-8 h-8"
                                src={experience?.created_by?.avatar ? experience?.created_by?.avatar : "/profile/avatar.jpg"}
                                alt={experience?.created_by?.name ? experience?.created_by?.name : "Nume utilizator"}
                            />
                            <span>adăugat de {experience?.created_by?.name ? experience?.created_by?.name : "Necunoscut"}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemListing;
