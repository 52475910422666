import { createSlice } from "@reduxjs/toolkit";

const listSlice = createSlice({
    name: "list",
    initialState: {
        togglePOIExp: true,
        toggleMapMod: true,
        filterTags: null,
        filterCategoryExp: null,
        filterCategoryPoi: null,
        keywordFilter: "",
    },
    reducers: {
        togglePoiExp(state) {
            // Folosit pentru afisarea experientelor sau a punctelor de interes pe harta
            // true - experience
            // false - point of interest
            state.togglePOIExp = !state.togglePOIExp;
        },
        toggleMapMode(state) {
            // Folosit pentru afisearea tipului de harta
            // true - default
            // false - satellite
            state.toggleMapMod = !state.toggleMapMod;
        },
        updateFilterTags(state, action) {
            state.filterTags = action.payload;
        },

        updatefilterCategoryExp(state, action) {
            state.filterCategoryExp = action.payload;
        },
        updatefilterCategoryPoi(state, action) {
            state.filterCategoryPoi = action.payload;
        },
        updateKeywordFilter(state, action) {
            state.keywordFilter = action.payload;
            console.log(state.keywordFilter);
        },
    },
});

export const listActions = listSlice.actions;

export default listSlice;
