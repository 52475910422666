import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, ZoomControl } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { boundsActions } from "../../slice/map/bounds-slice";
import { placeActions } from "../../slice/map/place-slice";
import { listActions } from "../../slice/map/list-slice";
import L from "leaflet";
import { getApiExperiencesFull, getApiPointsOfInterest, getApiStations } from "../../services/apiServices";
import Place from "./Place";

import MarkerClusterGroup from "react-leaflet-cluster";

import utils from "../../utils/utils";
import "./style.css";

// datele pentru harta tip grid
const v1map = {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attr: "copy; <a href=https://www.openstreetmap.org/copyright>OpenStreetMap</a> contributors",
};

// datele pentru harta tip satelit
const v2map = {
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    attr: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
};

// afisarea unui placeholder pe harta cu locatia actuala a utilizatorului (trebuie apasat buton)
const CenterMap = () => {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);
    const map = useSelector((state) => state.place.map);

    useEffect(() => {
        if (!map) return;

        const customControlerGetMap = L.Control.extend({
            options: {
                position: "bottomright",
            },

            onAdd: function () {
                const btn = L.DomUtil.create("button");
                btn.title = "Home Button";
                btn.innerHTML =
                    "<svg version='1.0' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 128.000000 128.000000' preserveAspectRatio='xMidYMid meet'><g transform='translate(0.000000,128.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'><path d='M576 1264 c-3 -9 -6 -40 -6 -69 l0 -53 -39 -7 c-67 -11 -174 -69 -239 -130 -67 -62 -134 -178 -147 -254 l-7 -41 -53 0 c-81 0 -85 -3 -85 -70 0 -67 4 -70 85 -70 l53 0 7 -41 c13 -76 80 -192 147 -254 65 -61 172 -119 239 -130 l39 -7 0 -53 c0 -81 3 -85 70 -85 67 0 70 4 70 85 l0 53 41 7 c76 13 192 80 254 147 61 65 119 172 130 239 l7 39 53 0 c81 0 85 3 85 70 0 67 -4 70 -85 70 l-53 0 -7 39 c-11 69 -71 179 -132 242 -61 65 -178 131 -252 144 l-41 7 0 53 c0 81 -3 85 -70 85 -44 0 -59 -4 -64 -16z m224 -286 c31 -15 77 -49 103 -75 26 -26 60 -72 75 -103 24 -49 27 -66 27 -160 0 -94 -3 -111 -27 -160 -35 -71 -107 -143 -178 -178 -49 -24 -66 -27 -160 -27 -94 0 -111 3 -160 27 -71 35 -143 107 -178 178 -37 76 -44 209 -14 288 38 99 121 184 219 223 75 30 219 23 293 -13z'/><path d='M585 805 c-85 -30 -137 -124 -114 -207 14 -55 72 -113 127 -127 121 -33 244 89 212 209 -27 100 -132 159 -225 125z'/></g></svg>";
                btn.className = "bg-[rgba(255,255,255,0.95)] h-8 px-1 rounded-full font-semibold text-base";

                btn.onclick = () => {
                    map.locate().on("locationfound", function (e) {
                        setPosition(e.latlng);
                        map.flyTo(e.latlng, map.getZoom());
                        setBbox(e.bounds.toBBoxString().split(","));
                    });

                    return position === null ? null : (
                        <Marker position={position} className="bg-black" icon={utils.changeColor("human")}>
                            <Popup>
                                Esti aici. <br />
                                Detalii locatie: <br />
                                <b>Sud-vest longitudine</b>: {bbox[0]} <br />
                                <b>Sud-vest latitudine</b>: {bbox[1]} <br />
                                <b>Nord-est longitudine</b>: {bbox[2]} <br />
                                <b>Nord-est latitudine</b>: {bbox[3]}
                            </Popup>
                        </Marker>
                    );
                };
                return btn;
            },
        });

        map.addControl(new customControlerGetMap());
    }, [map]);

    return null;
};

// afisarea in grid / satelit a hartii (trebuie apasat buton)
const ModeMap = () => {
    const map = useSelector((state) => state.place.map);
    const mapMode = useSelector((state) => state.list.toggleMapMod);
    const dispatch = useDispatch();
    const controlRef = useRef(null);

    useEffect(() => {
        if (!map) return;

        if (!controlRef.current) {
            // Dacă butonul nu există, creăm unul
            const customControlerMapMode = L.Control.extend({
                options: {
                    position: "bottomleft",
                },

                onAdd: function () {
                    const btn = L.DomUtil.create("button");
                    btn.className = "shadow-[0_2px_6px_4px_rgba(0,0,0,0.2)]";

                    const textSpan = document.createElement("span");
                    textSpan.textContent = mapMode ? "Grid" : "Satelit";
                    textSpan.className = mapMode
                        ? "absolute text-base text-white transform -translate-x-1/2 bottom-1 left-1/2 font-medium"
                        : "absolute text-base text-black transform -translate-x-1/2 bottom-1 left-1/2 font-medium";

                    const img = document.createElement("img");
                    img.src = mapMode ? "/map/satellite.png" : "/map/grid.png";
                    img.className = "w-16 h-16 border border-2 border-white rounded-md";

                    btn.appendChild(textSpan);
                    btn.appendChild(img);

                    btn.onclick = () => {
                        dispatch(listActions.toggleMapMode());
                    };
                    controlRef.current = btn; // Setăm referința la butonul creat
                    return btn;
                },
            });

            map.addControl(new customControlerMapMode());
        } else {
            // Dacă butonul există, actualizăm imaginea și acțiunea
            const img = controlRef.current.querySelector("img");
            const textSpan = controlRef.current.querySelector("span");
            if (mapMode) {
                img.src = "/map/satellite.png";
                textSpan.textContent = "Grid";
                textSpan.className = "absolute text-base font-medium text-white transform -translate-x-1/2 bottom-1 left-1/2";
            } else {
                img.src = "/map/grid.png";
                textSpan.textContent = "Satelit";
                textSpan.className = "absolute text-base font-medium text-black transform -translate-x-1/2 bottom-1 left-1/2";
            }

            // Actualizăm acțiunea butonului
            controlRef.current.onclick = () => {
                dispatch(listActions.toggleMapMode());
            };
        }
    }, [map, mapMode]);

    return null; // Nu returnăm nimic (null) în componentă, deoarece controlul Leaflet este creat direct în DOM.
};

// functie care cere permisunea locatie utilizatorului
function LocationMarker() {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);
    const dispatch = useDispatch();

    const map = useMap();

    useEffect(() => {
        map.locate()
            .on("locationfound", function (e) {
                setPosition(e.latlng);
                dispatch(placeActions.getUserLocation(e.latlng));
                // map.flyTo(e.latlng, map.getZoom());
                // const radius = e.accuracy;
                // const circle = L.circle(e.latlng, radius);
                // circle.addTo(map);
                setBbox(e.bounds.toBBoxString().split(","));
            })
            .on("locationerror", function (e) {
                // map.flyTo({ lat: 45.2412834, lng: 27.9318981 });
            });
    }, [map]);

    return position === null ? null : (
        <Marker position={position} icon={utils.changeColor("human")}>
            <Popup>
                Esti aici. <br />
                Detalii locatie: <br />
                <b>Sud-vest longitudine</b>: {bbox[0]} <br />
                <b>Sud-vest latitudine</b>: {bbox[1]} <br />
                <b>Nord-est longitudine</b>: {bbox[2]} <br />
                <b>Nord-est latitudine</b>: {bbox[3]}
            </Popup>
        </Marker>
    );
}

const UpdateCenterMap = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
        map.setView([lat, lng], 14);
    }, [lat, lng]);

    return null;
};

const UpdateCenterMapFilter = ({ coords }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(coords, 8);
    }, coords);

    return null;
};

function Map() {
    const dispatch = useDispatch();
    const newCenterMapLatitude = useSelector((state) => state.search.newCenterMapLat);
    const newCenterMapLogitude = useSelector((state) => state.search.newCenterMapLng);
    const mapMode = useSelector((state) => state.list.toggleMapMod);

    const toggleStationsRedux = useSelector((state) => state.bounds.toggleShowStations);

    const centerMapFilterRedux = useSelector((state) => state.bounds.centerMapFilter);
    const stateMapRedux = useSelector((state) => state.bounds.stateMap);
    const poisRedux = useSelector((state) => state.bounds.pointsOfInterestState);
    const expsRedux = useSelector((state) => state.bounds.experiencesState);
    const poisExperienceRedux = useSelector((state) => state.bounds.allPointsOfInterestOfExperiences);

    const refMapMode = useRef(null);

    const [experiences, setExperiences] = useState([]);
    const [pointsOfInterest, setPointsOfInterest] = useState([]);
    const [stations, setStations] = useState([]);

    const [isFetchingExp, setIsFetchingExp] = useState(true);
    const [isFetchingPoi, setIsFetchingPoi] = useState(true);
    const [isFetchingStations, setIsFetchingStations] = useState(true);

    // console.log("hartaaa", poisRedux);
    // console.log("poi", pointsOfInterest);

    useEffect(() => {
        if (refMapMode.current) {
            refMapMode.current.setUrl(mapMode ? v1map.url : v2map.url);
        }
    }, [mapMode]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiExperiencesFull();
            setIsFetchingExp(response.success && !response.error ? false : true);
            setExperiences(response.data);

            dispatch(boundsActions.getAllExperiencesUnchanged(response.data));
            dispatch(boundsActions.updateIsFetchingExp([response.success && !response.error ? false : true, response.error]));
            if (expsRedux.length === 0) {
                dispatch(boundsActions.getAllExperiences(response.data));
            }

            const response1 = await getApiPointsOfInterest();
            setIsFetchingPoi(response1.success && !response1.error ? false : true);
            setPointsOfInterest(response1.data.data);

            dispatch(boundsActions.getAllPointsOfInterestUnchanged(response1.data.data));
            dispatch(boundsActions.updateIsFetchingPoi([response1.success && !response1.error ? false : true, response1.error]));
            if (poisRedux.length === 0) dispatch(boundsActions.getAllPointsOfInterest(response1.data.data));

            const response2 = await getApiStations();
            setIsFetchingStations(response2.success && !response2.error ? false : true);
            setStations(response2.data);
        };

        fetchData();
    }, []);

    // incarcarea hartii in redux
    const updateMapData = (map) => {
        dispatch(placeActions.getMap(map));
    };

    return (
        <MapContainer
            center={[45.2412834, 27.9318981]}
            zoom={13}
            style={{ height: "100%", width: "100%" }}
            zoomControl={false}
            ref={updateMapData}>
            <ZoomControl position="bottomright" />
            <TileLayer
                ref={refMapMode}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url={mapMode ? v1map.url : v2map.url}
            />

            <MarkerClusterGroup chunkedLoading>
                {stateMapRedux
                    ? poisExperienceRedux.map((place, index) => (
                          <Marker
                              key={index}
                              position={[place.latitude, place.longitude]}
                              title={place.title}
                              icon={L.divIcon({
                                  html: `<svg style='fill:${place.point_of_interest_category.color}' class='MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='FmdGoodIcon'><path d='M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'></path></svg>`,
                                  className: "w-full h-full",
                                  iconSize: [40, 54],
                                  iconAnchor: [12, 30],
                              })}
                              //   eventHandlers={{
                              //       click: () => dispatch(listActions.statusPlaceList(place)),
                              //   }}
                          >
                              <Popup autoPan={false}>
                                  <Place place={place} key={index} onLocation={"map"} />
                              </Popup>
                          </Marker>
                      ))
                    : poisRedux.map((place, index) => (
                          <Marker
                              key={index}
                              position={[place.latitude, place.longitude]}
                              title={place.title}
                              icon={L.divIcon({
                                  html: `<svg style='fill:${place.point_of_interest_category.color}' class='MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='FmdGoodIcon'><path d='M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'></path></svg>`,
                                  className: "w-full h-full",
                                  iconSize: [40, 54],
                                  iconAnchor: [12, 30],
                              })}
                              //   eventHandlers={{
                              //       click: () => dispatch(listActions.statusPlaceList(place)),
                              //   }}
                          >
                              <Popup autoPan={false}>
                                  <Place place={place} key={index} onLocation={"map"} />
                              </Popup>
                          </Marker>
                      ))}
            </MarkerClusterGroup>

            <MarkerClusterGroup chunkedLoading>
                {!isFetchingStations &&
                    toggleStationsRedux &&
                    stations.map((station) => (
                        <Marker
                            key={station.id}
                            position={[station.latitude, station.longitude]}
                            title={station.name}
                            icon={L.divIcon({
                                html: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 64.000000 64.000000"
                                        preserveAspectRatio="xMidYMid meet">

                                        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M61 507 c-44 -65 -65 -137 -59 -210 8 -97 75 -218 113 -204 16 7 16
                                        9 1 30 -65 89 -80 135 -73 222 5 64 24 111 63 158 13 16 22 33 18 38 -12 19
                                        -34 7 -63 -34z"/>
                                        <path d="M516 541 c-4 -5 5 -22 18 -38 39 -47 58 -94 63 -158 7 -87 -8 -133
                                        -73 -222 -15 -21 -15 -23 1 -30 21 -8 53 28 87 97 27 55 31 185 9 240 -34 83
                                        -88 140 -105 111z"/>
                                        <path d="M127 458 c-46 -62 -59 -140 -36 -209 15 -44 56 -99 75 -99 16 0 19
                                        26 3 36 -21 13 -49 89 -49 134 0 45 28 121 49 134 6 4 11 14 11 22 0 25 -28
                                        15 -53 -18z"/>
                                        <path d="M460 476 c0 -8 5 -18 11 -22 21 -13 49 -89 49 -134 0 -45 -28 -121
                                        -49 -134 -16 -10 -13 -36 3 -36 19 0 60 55 75 99 23 69 10 147 -36 209 -25 33
                                        -53 43 -53 18z"/>
                                        <path d="M191 411 c-41 -57 -40 -133 3 -186 18 -22 27 -27 36 -19 8 9 6 20 -9
                                        45 -26 43 -27 95 -1 137 33 53 7 73 -29 23z"/>
                                        <path d="M405 431 c-3 -6 4 -25 15 -43 26 -42 25 -94 -1 -137 -15 -25 -17 -36
                                        -9 -45 9 -8 18 -3 36 19 43 53 44 129 3 186 -21 30 -34 36 -44 20z"/>
                                        <path d="M283 388 c-55 -27 -50 -114 8 -138 89 -37 151 99 63 139 -30 13 -42
                                        13 -71 -1z"/>
                                        </g>
                                    </svg>`,
                                className: "w-full h-full",
                                iconSize: [40, 54],
                                iconAnchor: [12, 30],
                            })}
                            // eventHandlers={{
                            //     click: () => dispatch(listActions.statusPlaceList(place)),
                            // }}
                        >
                            <Popup autoPan={false}>
                                <div className="w-72">
                                    <h1 className="text-lg font-semibold">Date curente</h1>
                                    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                                        <h2>Temperatură maximă</h2>
                                        <span>
                                            {station.max_temperature.value} {station.max_temperature.unit}
                                        </span>

                                        <h2>Temperatură minimă</h2>
                                        <span>
                                            {station.min_temperature.value} {station.min_temperature.unit}
                                        </span>

                                        <h2>Temperatură curentă</h2>
                                        <span>
                                            {station.current_temperature.value} {station.current_temperature.unit}
                                        </span>

                                        <h2>Presiune curentă</h2>
                                        <span>
                                            {station.current_pressure.value} {station.current_pressure.unit}
                                        </span>

                                        <h2>Umiditate curentă</h2>
                                        <span>
                                            {station.current_humidity.value} {station.current_humidity.unit}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    ))}
            </MarkerClusterGroup>

            <CenterMap />
            <ModeMap />
            <LocationMarker />
            {newCenterMapLatitude !== null && newCenterMapLogitude !== null && (
                <UpdateCenterMap lat={newCenterMapLatitude} lng={newCenterMapLogitude} />
            )}
            {centerMapFilterRedux !== null && <UpdateCenterMapFilter coords={centerMapFilterRedux} />}
        </MapContainer>
    );
}

export default Map;
