import { useEffect, useState } from "react";
import Layout from "./Layout";

import Review from "./Review";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const Reviews = ({ experience, reviews, isFetching, onButtonPressDelete }) => {
    const userData = getUserData();

    const handleButtonPressDelete = () => {
        // Apelăm funcția de callback pentru a trimite evenimentul către componenta parinte
        onButtonPressDelete();
    };

    return (
        <Layout title="Recenzii">
            {/* {!isFetching && <h1>{reviews.length}</h1>} */}
            <div className="flex flex-col py-3 space-y-5" id="SubmenuReviews">
                {isFetching ? (
                    <h2>Se incarca...</h2>
                ) : reviews.length === 0 ? (
                    <h2>Nu exista recenzii</h2>
                ) : (
                    reviews
                        .filter((review) => review.approved)
                        .map((review) => <Review review={review} key={review.id} onButtonPressDelete={onButtonPressDelete} />)
                )}
            </div>
        </Layout>
    );
};

export default Reviews;
