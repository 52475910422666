import ItemHowItWorks from "./ItemHowItWorks";

const HowItWorks = () => {
    return (
        <div className="container mx-auto mb-4">
            <div className="max-w-[570px] mx-auto">
                <h1 className="text-4xl font-bold text-center my-7 ">Cum funcționează</h1>
                <div className="w-16 rounded-full h-1 bg-[#4DB7FE] mx-auto" />
                <p className="mt-3 mb-16 text-center">
                    Experiențe autentice în Delta Dunării prin Smartsense! Stabilește legătura cu gazdele pentru experiențe memorabile și
                    creează-ți lista de destinații pentru viitor!
                </p>
            </div>

            <div className="flex flex-col items-center space-y-16">
                <div className="flex flex-col space-y-16 md:space-y-0 md:space-x-16 md:flex-row">
                    <ItemHowItWorks
                        image={"https://www.iconpacks.net/icons/2/free-location-icon-2955-thumb.png"}
                        title={"Caută locuri interesante"}
                        text={
                            "Descoperă locuri captivante pe platforma noastră. Alege dintr-o varietate de experiențe unice și începe aventura!"
                        }
                        number={"01"}
                    />
                    <ItemHowItWorks
                        image={
                            "https://static.vecteezy.com/system/resources/previews/010/056/094/original/email-and-mail-icon-sign-symbol-design-free-png.png"
                        }
                        title={"Contactează gazdele"}
                        text={"Conectează-te cu gazdele ușor și rapid. Experiențe autentice așteaptă să fie descoperite!"}
                        number={"02"}
                    />
                    <ItemHowItWorks
                        image={"https://www.pngkey.com/png/detail/44-449361_checklist-icon-file-checklist-icons-blue.png"}
                        title={"Personalizează-ți călătoria!"}
                        text={"Personalizează-ți aventura! Creează-ți lista de experiențe și puncte de interes preferate pe platformă"}
                        number={"03"}
                    />
                </div>

                <div className="flex items-center justify-center w-24 h-24 mx-auto bg-[#5ECFB1] border-[#f0f0f0] rounded-full border-[12px]">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-8 h-8 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M9.293 13.293L6.646 10.646a1 1 0 00-1.414 1.414l3.95 3.95a1 1 0 001.414 0l8.485-8.485a1 1 0 10-1.414-1.414L9.293 13.293z" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
