import { createSlice } from "@reduxjs/toolkit";

const boundsSlice = createSlice({
    name: "bounds",
    initialState: {
        tagsIDs: [],
        countExperiences: true,
        countPOI: true,
        allPointsOfInterest: [],
        pointsOfInterestState: [],
        allExperiences: [],
        allPointsOfInterestOfExperiences: [],
        experiencesState: [],
        stateMap: true, // true - exp, false - poi
        centerMapFilter: null,
        isFetchingExp: true,
        isErrorExp: false,
        isFetchingPoi: true,
        isErrorPoi: false,
        toggleShowStations: true,
        toggleShowExpPoi: true,
    },
    reducers: {
        getTagsId(state, action) {
            const tagsId = action.payload;
            state.tagsIDs = tagsId;
            state.tagsExpIDs = tagsId;
        },

        getAllPointsOfInterestUnchanged(state, action) {
            state.allPointsOfInterest = action.payload;
        },
        getAllPointsOfInterest(state, action) {
            state.pointsOfInterestState = action.payload;
        },
        getAllExperiencesUnchanged(state, action) {
            state.allExperiences = action.payload;
        },
        getAllExperiences(state, action) {
            const experiences = action.payload;
            // console.log("aici merge cand se incarca pagina?");

            state.experiencesState = experiences;

            let temp = [];

            experiences.forEach((experience) => {
                experience.points_of_interest.forEach((poi) => {
                    // Verific daca ID-ul obiectului nu exista deja in array-ul temp
                    const exists = temp.some((item) => item.id === poi.id);

                    // Daca obiectul nu exista deja in array, il adaugam
                    if (!exists) {
                        temp.push(poi);
                    }
                });
            });

            // console.log("ce iese de aici din temp", temp);

            state.allPointsOfInterestOfExperiences = temp;
        },
        updateMapState(state) {
            state.stateMap = !state.stateMap;
            // console.log(state.stateMap);
        },
        updateAllPointsOfInterest(state) {
            state.pointsOfInterestState = state.allPointsOfInterest;
        },
        updateAllExperiences(state) {
            state.experiencesState = state.allExperiences;
            let temp = [];

            state.allExperiences.forEach((experience) => {
                experience.points_of_interest.forEach((poi) => {
                    // Verific daca ID-ul obiectului nu exista deja in array-ul temp
                    const exists = temp.some((item) => item.id === poi.id);

                    // Daca obiectul nu exista deja in array, il adaugam
                    if (!exists) {
                        temp.push(poi);
                    }
                });
            });

            // console.log("ce iese de aici din temp", temp);

            state.allPointsOfInterestOfExperiences = temp;
        },
        updateCenterMapExp(state) {
            // state.centerMapFilter;
            // console.log("dada", [...state.allPointsOfInterestOfExperiences]);
            let latSum = 0;
            let lngSum = 0;

            state.allPointsOfInterestOfExperiences.forEach((poi) => {
                latSum += poi.latitude;
                lngSum += poi.longitude;
            });

            const numberOfPois = state.allPointsOfInterestOfExperiences.length;

            if (numberOfPois > 0) {
                let lat = latSum / numberOfPois;
                let lng = lngSum / numberOfPois;

                state.centerMapFilter = [lat, lng];
            } else {
                state.centerMapFilter = [45.2412834, 27.9318981];
            }
        },
        updateCenterMapPoi(state) {},
        updateIsFetchingExp(state, action) {
            state.isFetchingExp = action.payload[0];
            state.isErrorExp = action.payload[1];
        },
        updateIsFetchingPoi(state, action) {
            state.isFetchingPoi = action.payload[0];
            state.isErrorPoi = action.payload[1];
        },
        updateToggleStations(state) {
            state.toggleShowStations = !state.toggleShowStations;
        },
    },
});

export const boundsActions = boundsSlice.actions;

export default boundsSlice;
