import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Header";
import Footer from "../Header";
import DashboardMenu from "../DashboardMenu";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function UserPointsofinterest() {
    const [pointsofinterest, setPointsofinterest] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = getUserData();

    useEffect(() => {
        setLoading(true);
        axios({
            method: "get",
            url: process.env.REACT_APP_API_URL + "/mobile/users/" + userData.user.id + "/points-of-interest",
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then(
            (response) => {
                setPointsofinterest(response.data.data);
                setLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const linkTemplate = (rowData) => {
        return (
            <div className="flex flex-col">
                <Link to={"/pointofinterest/" + rowData.id}>Vizualizează</Link>
                <Link to={"/pointofinterest/" + rowData.id + "/edit"}>Editează</Link>
            </div>
        );
    };

    return (
        <>
            <div className="bg-[rgb(239,243,248)] min-h-full">
                <Header />
                <div className="container box-border py-8 mx-auto my-10 bg-white rounded-lg newtheme">
                    <DashboardMenu />
                    <div className="col-md-9 col-sm-12">
                        <div className="dashboard-list-box fl-wrap">
                            <div className="dashboard-title fl-wrap">
                                <h3>Punctele tale de interes</h3>
                            </div>
                            <DataTable
                                value={pointsofinterest}
                                paginator
                                responsiveLayout="stack"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Arată de la {first} la {last} din {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                loading={loading}>
                                <Column field="title" header="Denumire"></Column>
                                <Column
                                    field="description"
                                    header="Descriere"
                                    body={(rowData) => `${rowData.description.substring(0, 80)}...`}></Column>
                                <Column field="address" header="Adresă"></Column>
                                <Column field="phone_number" header="Telefon"></Column>
                                <Column header="Acțiuni" body={linkTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default UserPointsofinterest;
