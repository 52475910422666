import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";

import { getApiTags, getApiPointOfInterestOfUser, getApiExperience } from "../../services/apiServices";

import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";

import "./style.css";
import DashboardMenu from "../DashboardMenu";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const testare = [
    {
        id: 1113,
        title: "sala",
        location: "Base64 click",
        description: "salasalasalasala",
        youtube_url: "",
        facebook_url: "",
        website_url: "",
        images_upload: [],
        start_date: "2024-02-20T22:00:00.000Z",
        end_date: "2024-02-20T22:00:00.000Z",
        tags_ids: [
            {
                id: 1,
                tag: "Fauna",
                icon_url: "images/icons/tag/OC4jsDVLqS.png",
                color: "#FFEB04",
            },
        ],
        points_of_interest: [
            {
                id: 1085,
                title: "bulgaras21231",
                description: "bulgaras2312312",
                address: "București, ROU",
                phone_number: "1231231231",
                latitude: 44.414528011057,
                longitude: 25.999776161017,
                mesh_name: "-",
                tags: [
                    {
                        id: 1,
                        tag: "Fauna",
                        icon_url: "images/icons/tag/OC4jsDVLqS.png",
                        color: "#FFEB04",
                    },
                ],
                images: [
                    {
                        id: 2750,
                        title: "cropped-best_volleyball_equipment.jpeg",
                        url: "images/point_of_interest/EDv5XEy1nv.jpeg",
                    },
                ],
                point_of_interest_category: {
                    id: 3,
                    title: "Divertisment",
                    icon_url: "images/icons/poi_category/category btk6HfJmhJY.png",
                    color: "#3e643e",
                },
                created_by: {
                    id: 51,
                    name: "bulgaras2",
                    user_avatar: "https://dev2.smartsense.ro/storage/images/avatars/default_avatar.png",
                },
                active: true,
                visible: true,
            },
        ],
        experience_category_id: 1,
        active: true,
        ar_prefab: "",
        has_ar: false,
        has_vuforia: false,
        score: 0,
        status: "in_progress",
        user_id: 1,
    },
    {
        id: 1112,
        title: "cara",
        location: "Base64 click",
        description: "salasalasalasala",
        youtube_url: "",
        facebook_url: "",
        website_url: "",
        images_upload: [],
        start_date: "2024-02-20T22:00:00.000Z",
        end_date: "2024-02-20T22:00:00.000Z",
        tags_ids: [
            {
                id: 1,
                tag: "Fauna",
                icon_url: "images/icons/tag/OC4jsDVLqS.png",
                color: "#FFEB04",
            },
        ],
        points_of_interest: [
            {
                id: 1085,
                title: "bulgaras21231",
                description: "bulgaras2312312",
                address: "București, ROU",
                phone_number: "1231231231",
                latitude: 44.414528011057,
                longitude: 25.999776161017,
                mesh_name: "-",
                tags: [
                    {
                        id: 1,
                        tag: "Fauna",
                        icon_url: "images/icons/tag/OC4jsDVLqS.png",
                        color: "#FFEB04",
                    },
                ],
                images: [
                    {
                        id: 2750,
                        title: "cropped-best_volleyball_equipment.jpeg",
                        url: "images/point_of_interest/EDv5XEy1nv.jpeg",
                    },
                ],
                point_of_interest_category: {
                    id: 3,
                    title: "Divertisment",
                    icon_url: "images/icons/poi_category/category btk6HfJmhJY.png",
                    color: "#3e643e",
                },
                created_by: {
                    id: 51,
                    name: "bulgaras2",
                    user_avatar: "https://dev2.smartsense.ro/storage/images/avatars/default_avatar.png",
                },
                active: true,
                visible: true,
            },
        ],
        experience_category_id: 1,
        active: true,
        ar_prefab: "",
        has_ar: false,
        has_vuforia: false,
        score: 0,
        status: "in_progress",
        user_id: 1,
    },
    {
        id: 1111,
        title: "dara",
        location: "Base64 click",
        description: "salasalasalasala",
        youtube_url: "",
        facebook_url: "",
        website_url: "",
        images_upload: [],
        start_date: "2024-02-20T22:00:00.000Z",
        end_date: "2024-02-20T22:00:00.000Z",
        tags_ids: [
            {
                id: 1,
                tag: "Fauna",
                icon_url: "images/icons/tag/OC4jsDVLqS.png",
                color: "#FFEB04",
            },
        ],
        points_of_interest: [
            {
                id: 1085,
                title: "bulgaras21231",
                description: "bulgaras2312312",
                address: "București, ROU",
                phone_number: "1231231231",
                latitude: 44.414528011057,
                longitude: 25.999776161017,
                mesh_name: "-",
                tags: [
                    {
                        id: 1,
                        tag: "Fauna",
                        icon_url: "images/icons/tag/OC4jsDVLqS.png",
                        color: "#FFEB04",
                    },
                ],
                images: [
                    {
                        id: 2750,
                        title: "cropped-best_volleyball_equipment.jpeg",
                        url: "images/point_of_interest/EDv5XEy1nv.jpeg",
                    },
                ],
                point_of_interest_category: {
                    id: 3,
                    title: "Divertisment",
                    icon_url: "images/icons/poi_category/category btk6HfJmhJY.png",
                    color: "#3e643e",
                },
                created_by: {
                    id: 51,
                    name: "bulgaras2",
                    user_avatar: "https://dev2.smartsense.ro/storage/images/avatars/default_avatar.png",
                },
                active: true,
                visible: true,
            },
        ],
        experience_category_id: 1,
        active: true,
        ar_prefab: "",
        has_ar: false,
        has_vuforia: false,
        score: 0,
        status: "in_progress",
        user_id: 1,
    },
];
const moara = [
    {
        id: 1113,
        title: "sala",
        location: "Base64 click",
        description: "salasalasalasala",
        youtube_url: "",
        facebook_url: "",
        website_url: "",
        images_upload: [],
        start_date: "2024-02-20T22:00:00.000Z",
        end_date: "2024-02-20T22:00:00.000Z",
        tags_ids: [
            {
                id: 1,
                tag: "Fauna",
                icon_url: "images/icons/tag/OC4jsDVLqS.png",
                color: "#FFEB04",
            },
        ],
        points_of_interest: [
            {
                id: 1085,
                title: "bulgaras21231",
                description: "bulgaras2312312",
                address: "București, ROU",
                phone_number: "1231231231",
                latitude: 44.414528011057,
                longitude: 25.999776161017,
                mesh_name: "-",
                tags: [
                    {
                        id: 1,
                        tag: "Fauna",
                        icon_url: "images/icons/tag/OC4jsDVLqS.png",
                        color: "#FFEB04",
                    },
                ],
                images: [
                    {
                        id: 2750,
                        title: "cropped-best_volleyball_equipment.jpeg",
                        url: "images/point_of_interest/EDv5XEy1nv.jpeg",
                    },
                ],
                point_of_interest_category: {
                    id: 3,
                    title: "Divertisment",
                    icon_url: "images/icons/poi_category/category btk6HfJmhJY.png",
                    color: "#3e643e",
                },
                created_by: {
                    id: 51,
                    name: "bulgaras2",
                    user_avatar: "https://dev2.smartsense.ro/storage/images/avatars/default_avatar.png",
                },
                active: true,
                visible: true,
            },
        ],
        experience_category_id: 1,
        active: true,
        ar_prefab: "",
        has_ar: false,
        has_vuforia: false,
        score: 0,
        status: "in_progress",
        user_id: 1,
    },
];

// formatare datei de la calendar pentru endpoint backend
function format_date(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
}

function formatDateString(originalDate) {
    return new Date(originalDate).toISOString().slice(0, 10);
}

function EditExperience() {
    const { experienceId } = useParams();
    const inputRef = useRef(null);
    const toast = useRef(null);
    let userData = getUserData();

    const [pointsOfInterest, setPointsOfInterest] = useState(null);
    const [tags, setTags] = useState(null);
    const [experience, setExperience] = useState(null);

    const [isFetchingPointsOfInterest, setIsFetchingPointsOfInterest] = useState(true);
    const [isFetchingTags, setIsFetchingTags] = useState(true);
    const [isFetchingExperience, setIsFetchingExperience] = useState(true);

    const [theImages, setTheImages] = useState(null);
    const [deletedImages, setDeletedImages] = useState([]);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiTags();
            setIsFetchingTags(!(response.success && !response.error));
            setTags(response.data);

            const response1 = await getApiExperience(experienceId);
            setIsFetchingExperience(!(response1.success && !response1.error));
            setExperience(response1.data);

            const response2 = await getApiPointOfInterestOfUser(userData?.user?.id, userData?.token);
            setIsFetchingPointsOfInterest(!(response2.success && !response2.error));
            const dummy = response2.data;
            const modifiedPointsOfInterest = dummy.map((poiItem) => {
                const { experiences, ...rest } = poiItem;
                return rest;
            });
            setPointsOfInterest(modifiedPointsOfInterest);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!isFetchingExperience && experience) {
            const { data } = experience;
            console.log("data este experienta", data);

            const formValues = {
                id: data.id,
                title: data.title || "",
                location: data.location || "",
                description: data.description || "",
                youtube_url: data.youtube_url || "",
                facebook_url: data.facebook_url || "",
                website_url: data.website_url || "",
                images_upload: data.images_upload || [],
                start_date: new Date(data.start_date) || null,
                end_date: new Date(data.end_date) || null,
                tags_ids: data.tags || [],
                points_of_interest: data.points_of_interest || [],
                experience_category_id: data.experience_category_id || 1,
                active: data.active || false,
                ar_prefab: data.ar_prefab || "",
                has_ar: data.has_ar || false,
                has_vuforia: data.has_vuforia || false,
                score: data.score || 0,
                status: data.status || "finished",
                user_id: data.user_id || 1,
            };

            formik.setValues(formValues);
            console.log("formValues", formValues.points_of_interest);

            setTheImages(data.images);
        }
    }, [experience, isFetchingExperience]);

    // afisare toast succes
    const showSuccess = (detailValue) => {
        toast.current.show({ severity: "success", summary: "Informația a fost trimisă cu succes", detail: detailValue, life: 3000 });
    };

    // afisare toast error
    const showError = (detailValue) => {
        toast.current.show({
            severity: "error",
            summary: "Informația nu a putut fi trimisă către server",
            detail: detailValue,
            life: 3000,
        });
    };

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            location: "",
            score: 0,
            has_ar: false,
            has_vuforia: false,
            ar_prefab: "",
            status: "in_progress",
            start_date: null,
            end_date: null,
            active: true,
            tags_ids: [],
            experience_category_id: 1,
            points_of_interest: [],
            images_upload: [],
            youtube_url: "",
            facebook_url: "",
            website_url: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.title) {
                errors.title = "Titlul este necesar.";
            }

            if (!data.description) {
                errors.description = "Descrierea este necesară.";
            } else if (data.description.length > 2000) {
                errors.description = "Ai atins maximul de caractere.";
            } else if (data.description.length < 10) {
                errors.description = `O descriere minimă de 1000 caractere este necesară. Mai ai de adaugat ${
                    1000 - data.description.length
                } caractere.`;
            }

            if (!data.location) {
                errors.location = "Locatia este necesara.";
            }

            if (!data.start_date) {
                errors.start_date = "Starting date is required.";
            }

            if (!data.end_date) {
                errors.end_date = "Finishing date is required.";
            }

            if (data.start_date && data.end_date) {
                if (data.start_date.getTime() > data.end_date.getTime()) {
                    errors.start_date = "Data de începere trebuie să fie mai mică sau aceeasi față de data de sfârșire.";
                }
            }

            // if (!data.status) {
            //     errors.status = "Statusul este necesar.";
            // }

            return errors;
        },
        onSubmit: (data) => {
            let idsTags = [];
            let idsPointsOfInterest = [];

            data.tags_ids.forEach((exp) => {
                idsTags.push(exp.id);
            });
            data.points_of_interest.forEach((poi) => {
                idsPointsOfInterest.push(poi.id);
            });

            axios({
                method: "put",
                url: process.env.REACT_APP_API_URL + "/web/experiences/" + experienceId,
                headers: {
                    Authorization: "Bearer " + userData.token.split("|")[1],
                },
                data: {
                    ...data,
                    point_of_interest_ids: idsPointsOfInterest,
                    start_date: format_date(data.start_date),
                    end_date: format_date(data.end_date),
                    tags_ids: idsTags,
                    user_id: userData.user.id,
                    delete_ids: deletedImages,
                    created_by_id: userData.user.id,
                },
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            })
                .then((response) => {
                    showSuccess(response?.data?.message);
                })
                .catch((error) => {
                    console.log(error.response);
                    if (error.response) {
                        showError(error.response.status);
                    } else if (error.request) {
                        showError(error.request);
                    } else {
                        showError(error.message);
                    }
                });
        },
    });

    // transformarea imaginilor in base64 si incarcarea lor in fieldul de images al formului
    const customBase64Uploader = async (event) => {
        let images = event.files;
        let temp = [];
        Object.keys(images).forEach((image) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(images[image]);
            fileReader.onload = () => {
                temp.push({
                    title: images[image].name,
                    image: fileReader.result,
                });
            };
        });

        formik.handleChange({
            target: { name: "images_upload", value: temp },
        });
    };

    // functii folosite pentru afisare erori la fields - formik
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const removeImage = (_id) => {
        console.log(_id);
        const _theImages = theImages;
        const _theImage = _theImages.findIndex((x) => x.id === _id);
        var __theImages = [...theImages];
        delete __theImages[_theImage];
        __theImages = __theImages.filter((x) => x);

        setTheImages(__theImages);
        setDeletedImages([...deletedImages, _id]);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="bg-[rgb(239,243,248)] min-h-full">
                <Header />
                <div className="container box-border py-8 mx-auto my-10 bg-white rounded-lg newtheme">
                    <DashboardMenu />
                    <div className="col-md-9 col-sm-12">
                        <form onSubmit={formik.handleSubmit} className="space-y-6">
                            <div className="dashboard-title fl-wrap">
                                <h3>Adaugă o experiență</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <label>
                                                Denumire <i className="gmui gmui-briefcase"></i>
                                            </label>
                                            {getFormErrorMessage("title")}
                                            <InputText
                                                id="title"
                                                name="title"
                                                value={formik.values.title}
                                                onChange={formik.handleChange}
                                                autoFocus
                                                className={classNames("w-full", { "p-invalid": isFormFieldValid("title") })}
                                            />
                                        </div>
                                        <div className="flex items-center justify-center mt-2 col-md-2">
                                            <div className="row">
                                                <div>
                                                    <Checkbox
                                                        inputId="active"
                                                        name="active"
                                                        checked={formik.values.active}
                                                        onChange={formik.handleChange}
                                                        className={classNames({
                                                            "p-invalid newtheme-checkbox": isFormFieldValid("active"),
                                                        })}
                                                    />
                                                    <label
                                                        htmlFor="active"
                                                        className={classNames("newtheme-checkbox-label", {
                                                            "p-error": isFormFieldValid("active"),
                                                        })}>
                                                        Activ
                                                    </label>
                                                    {getFormErrorMessage("active")}
                                                </div>
                                            </div>
                                            {/* <div className="mt-2 row">
                                                    <div>
                                                        <Checkbox
                                                            inputId="visible"
                                                            name="visible"
                                                            checked={formik.values.visible}
                                                            onChange={formik.handleChange}
                                                            className={classNames({
                                                                "p-invalid newtheme-checkbox": isFormFieldValid("visible"),
                                                            })}
                                                        />
                                                        <label
                                                            htmlFor="visible"
                                                            className={classNames("newtheme-checkbox-label", {
                                                                "p-error": isFormFieldValid("visible"),
                                                            })}>
                                                            Vizibil
                                                        </label>
                                                        {getFormErrorMessage("visible")}
                                                    </div>
                                                </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="description" className={classNames({ "p-error": isFormFieldValid("description") })}>
                                            Descriere
                                        </label>
                                        {getFormErrorMessage("description")}
                                        <InputTextarea
                                            rows={5}
                                            cols={30}
                                            id="description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            className={classNames("w-full mb-3", { "p-invalid": isFormFieldValid("description") })}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    {!isFetchingTags && (
                                        <div className="col-md-12">
                                            <label>Cuvinte cheie</label>
                                            {getFormErrorMessage("tags")}
                                            <div className="relative">
                                                {" "}
                                                <MultiSelect
                                                    id="tags_ids"
                                                    name="tags_ids"
                                                    value={formik.values.tags_ids}
                                                    onChange={formik.handleChange}
                                                    options={tags}
                                                    optionLabel="tag"
                                                    className="w-full"
                                                />
                                                <i className="absolute transform gmui gmui-key top-3.5 left-3"></i>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="start_date" className={classNames({ "p-error": isFormFieldValid("start_date") })}>
                                            Dată începere*
                                        </label>
                                        {getFormErrorMessage("start_date")}
                                        <div className="relative">
                                            <Calendar
                                                id="start_date"
                                                name="start_date"
                                                value={formik.values.start_date}
                                                onChange={formik.handleChange}
                                                dateFormat="dd/mm/yy"
                                                mask="99/99/9999"
                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-calendar top-3.5 left-3 z-10"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="end_date" className={classNames({ "p-error": isFormFieldValid("end_date") })}>
                                            Dată sfarșire*
                                        </label>
                                        {getFormErrorMessage("end_date")}
                                        <div className="relative">
                                            <Calendar
                                                id="end_date"
                                                name="end_date"
                                                value={formik.values.end_date}
                                                onChange={formik.handleChange}
                                                dateFormat="dd/mm/yy"
                                                mask="99/99/9999"
                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-calendar top-3.5 left-3 z-10"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Locatie</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label>
                                                Adresa<i className="gmui gmui-map-marker"></i>
                                            </label>
                                            {getFormErrorMessage("location")}
                                            <InputText
                                                id="location"
                                                name="location"
                                                value={formik.values.location}
                                                onChange={formik.handleChange}
                                                className={classNames("w-full positionField", {
                                                    "p-invalid": isFormFieldValid("address"),
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Imagini</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="row">
                                    <label>Imagini</label>
                                    <div className="add-list-media-wrap">
                                        <div className="listsearch-input-item fl-wrap">
                                            <div className="fuzone">
                                                <FileUpload
                                                    // ref={inputRef}
                                                    customUpload
                                                    onSelect={customBase64Uploader}
                                                    name="images_upload"
                                                    multiple
                                                    accept="image/*"
                                                    emptyTemplate={
                                                        <div className="fu-text">
                                                            <span>
                                                                <i className="fa fal-images gmui gmui-images"></i> Drag-and-drop imagini
                                                                aici
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                                {theImages?.length > 0 && (
                                                    <div style={{ padding: "0 10px" }}>
                                                        {theImages.map(function (object, i) {
                                                            return (
                                                                <div className="float-left mb-6 mr-1" key={i} style={{ maxWidth: 250 }}>
                                                                    <img
                                                                        className="mt-6 mb-1"
                                                                        style={{ display: "inline-block" }}
                                                                        src={"http://dev2.smartsense.ro/storage/" + object.url}></img>
                                                                    <span
                                                                        onClick={() => removeImage(object.id)}
                                                                        className="text-base color2-bg p-button p-component p-button-raised p-button-secondary p-button-text p-button-sm">
                                                                        Elimina
                                                                    </span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Social</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>URL YouTube</label>
                                        {getFormErrorMessage("youtube_url")}
                                        <div className="relative">
                                            {" "}
                                            {/* Utilizăm clasa "relative" pentru a poziționa iconița */}
                                            <InputText
                                                id="youtube_url"
                                                name="youtube_url"
                                                value={formik.values.youtube_url}
                                                onChange={formik.handleChange}
                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-youtube top-3.5 left-3"></i>
                                        </div>
                                    </div>
                                    <div className="my-3 col-sm-12">
                                        <label>Facebook Url</label>
                                        {getFormErrorMessage("facebook_url")}
                                        <div className="relative">
                                            <InputText
                                                id="facebook_url"
                                                name="facebook_url"
                                                value={formik.values.facebook_url}
                                                onChange={formik.handleChange}
                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-facebook top-3.5 left-3"></i>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <label>Website Url</label>
                                        {getFormErrorMessage("website_url")}
                                        <div className="relative">
                                            <InputText
                                                id="website_url"
                                                name="website_url"
                                                value={formik.values.website_url}
                                                onChange={formik.handleChange}
                                                className="w-full"
                                            />
                                            <i className="absolute transform gmui gmui-website top-3.5 left-3"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboard-title dt-inbox fl-wrap">
                                <h3>Puncte de interes</h3>
                            </div>
                            <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                    <div className="row">
                                        {!isFetchingPointsOfInterest && pointsOfInterest && (
                                            <div className="col-md-12 col-sm-12">
                                                <label>
                                                    Asociază punctul de interes la următoarele experiențe:{" "}
                                                    <i className="gmui gmui-location customKey"></i>
                                                </label>
                                                <div className="listsearch-input-item">
                                                    {console.log(formik.values.points_of_interest)}
                                                    <MultiSelect
                                                        id="points_of_interest"
                                                        name="points_of_interest"
                                                        value={formik.values.points_of_interest}
                                                        onChange={formik.handleChange}
                                                        options={pointsOfInterest}
                                                        optionLabel="title"
                                                        className="w-full"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <Button type="submit" label="Salvează" className="mx-auto mt-4 mb-4 newtheme-submit" />
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default EditExperience;
