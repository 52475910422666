import { useEffect, useState } from "react";
import DashboardMenu from "../DashboardMenu";
import Footer from "../Footer";
import Header from "../Header";
import { getApiReviewsOfExperiencesOfUser } from "../../services/apiServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import axios from "axios";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear().toString().slice(2);
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
};

const ReviewRecievedUser = () => {
    const [reviews, setReviews] = useState(null);
    const [isFatching, setIsFatching] = useState(true);
    const [deleteUpdate, setDeleteUpdate] = useState(0);
    let userData = getUserData();

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiReviewsOfExperiencesOfUser(userData.user.id, userData.token.split("|")[1]);
            setIsFatching(!(response.success && !response.error));
            setReviews(response.data);
        };

        if (userData) fetchData();
    }, [deleteUpdate]);

    const actionTemplate = (rowData) => {
        return (
            <Link to={"/experience/" + rowData.experience_id} target="_blank">
                Vizualizează
            </Link>
        );
    };

    return (
        <div className="bg-[rgb(239,243,248)] min-h-full">
            <Header />
            <div className="container box-border py-8 mx-auto my-10 bg-white rounded-lg newtheme">
                <DashboardMenu />
                <div className="col-md-9 col-sm-12">
                    <div className="dashboard-list-box fl-wrap">
                        <div className="dashboard-title fl-wrap">
                            <h3>Recenzii primite</h3>
                        </div>
                        <DataTable
                            value={reviews}
                            paginator
                            responsiveLayout="stack"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Arată de la {first} la {last} din {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            loading={isFatching}>
                            <Column field="id" header="Id"></Column>
                            <Column field="user" header="Autor recenzie"></Column>
                            <Column field="created_at" header="Dată recenzie" body={(rowData) => formatDate(rowData.created_at)}></Column>
                            <Column field="score" header="Scor"></Column>
                            <Column header="Actiuni" body={actionTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ReviewRecievedUser;
