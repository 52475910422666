import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import DashboardMenu from "../DashboardMenu";
import { useNavigate } from "react-router-dom";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

function UserNotifications() {
    const [notifications, setNotifications] = useState([]);
    const userData = getUserData();

    const formik = useFormik({
        initialValues: {
            body: "",
            score: null,
            approved: true,
            user_id: 1,
            images_upload: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.score) {
                errors.score = "Score is required.";
            }

            return errors;
        },
        onSubmit: (data) => {
            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL + "/web/reviews",
                data: {
                    ...data,
                    score: data.score,
                    user_id: userData.user.id,
                },
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            })
                .then((response) => {
                    formik.resetForm();
                    // handleCancel();
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.status);
                        // showError(error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                        // showError(error.request);
                    } else {
                        console.log(error.message);
                        // showError(error.message);
                    }
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    var moment = require("moment"); // require

    const [reviews, setReviews] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const _navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            axios({
                method: "get",
                url: process.env.REACT_APP_API_URL + "/web/notifications",
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            }).then(
                (response) => {
                    setIsFetching(response.success === true && response.error === false ? false : true);
                    setNotifications(response.data.data);
                },
                (error) => {
                    console.log(error);
                }
            );
        };

        fetchData();
    }, [isFetching]);

    const ndate = (date) => {
        var ndate = moment(date);
        return ndate.format("DD/MM/YYYY");
    };

    const notificationClick = (notification) => {
        let url = "/";
        switch (notification.data.resource_type) {
            case "App\\Models\\PointOfInterest":
                url = "/pointofinterest/" + notification.data.resource_id;
                break;

            case "App\\Models\\Experience":
                url = "/experience/" + notification.data.resource_id;
                break;

            case "App\\Models\\Sensor":
                url = "/sensors/";
                break;

            default:
                break;
        }

        const data = {
            notification_id: notification.id,
        };
        axios({
            method: "put",
            url: process.env.REACT_APP_API_URL + "/web/notifications/mark-read",
            data: data,
            headers: {
                Authorization: `Bearer ${userData.token}`,
            },
        }).then(() => {
            _navigate(url);
        });
    };

    return (
        <div className="bg-[rgb(239,243,248)] min-h-full">
            <Header />
            <div className="container box-border py-8 mx-auto my-10 bg-white rounded-lg newtheme">
                <DashboardMenu />
                <div className="col-md-9 col-sm-12">
                    <div className="dashboard-list-box fl-wrap">
                        <div className="dashboard-title fl-wrap">
                            <h3>Notificări</h3>
                        </div>

                        {isFetching && (
                            <div className="box-border mx-auto bg-white rounded-lg">
                                <div className="_notificationList">
                                    {notifications.length > 0 ? (
                                        notifications.map((notification, index) => (
                                            <div
                                                onClick={(e) => notificationClick(notification)}
                                                className={notification.read_at ? "read notification-item" : "unread notification-item"}
                                                key={index}
                                                style={{ position: "relative", cursor: "pointer" }}>
                                                <div style={{ position: "absolute", right: "20px" }}>{ndate(notification.created_at)}</div>
                                                <img
                                                    className="float-left notification-item-img"
                                                    height={80}
                                                    width={80}
                                                    src={notification.data.image_url}></img>
                                                <div className="float-left _notification-item-text">
                                                    <b>{notification.data.title}</b>
                                                    <p>{notification.data.body}</p>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        ))
                                    ) : (
                                        <h2>Nicio notificare momentan.</h2>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default UserNotifications;
