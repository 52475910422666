import { Link } from "react-router-dom";
import styled from "styled-components";

const GradientBgFigure = styled.div`
    position: absolute;
    width: 550px;
    height: 200px;
    border-radius: 200px;
    background: rgba(255, 255, 255, 0.07);
    transform: rotate(35deg);
    box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.03);
`;

const CirclePulseFigure = styled.div`
    position: absolute;
    height: 200px;
    width: 200px;
    animation: bubble-anim 4s ease-out infinite;
    display: block;
    background: radial-gradient(circle at 10px 10px, rgba(255, 255, 255, 0.31), rgba(255, 255, 255, 0.11));
    border-radius: 100%;
`;

const OurApp = () => {
    return (
        <div className="bg-gradient-to-t to-[#6DB6FD] from-[#6FCFFD] flex flex-col md:flex-row relative overflow-hidden">
            <GradientBgFigure
                style={{
                    right: "-30px",
                    top: "10px",
                }}
            />
            <GradientBgFigure
                style={{
                    left: "-20px",
                    bottom: "30px",
                }}
            />

            <CirclePulseFigure className="left-1/3 -top-8" />
            <CirclePulseFigure className="right-1/3 -bottom-8" />
            <div className="container grid grid-cols-1 my-20 lg:grid-cols-2 md: lg:gap-x-28 gap-x-0 lg:gap-y-0 gap-y-10">
                <div className="flex flex-col justify-center order-1 mx-10 text-white ">
                    <h2 className="text-4xl font-extrabold text-white">Aplicația noastră va fi disponibilă in curand</h2>
                    <p>
                        Dacă navighezi pe această pagină de pe un smartphone Android, poți face experiența ta online și mai plăcută prin
                        descărcarea aplicației noastre pentru dispozitive mobile.
                    </p>
                    <p>
                        Explorează experiențe unice și destinații captivante cu aplicația noastră AR și VR. Călătorește în mod personalizat
                        și inovator!
                    </p>
                    <div className="flex flex-col space-x-0 space-y-3 xl:space-x-10 xl:flex-row xl:space-y-0">
                        <a target='_blank' 
                           rel='noopener noreferrer' 
                           href="https://play.google.com/store/apps/details?id=com.Altfactor.Smartsense"
                           className="flex items-center justify-between w-48 px-3 bg-white border-4 border-[#A7D8FC] rounded-full h-14">
                           
                           <span className="w-8 h-8 bg-[#6DB6FD] rounded-full flex items-center justify-center">
                                <svg
                                    stroke="white"
                                    fill="white"
                                    strokeWidth="0"
                                    viewBox="0 0 1024 1024"
                                    height="20px"
                                    width="20px"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M270.1 741.7c0 23.4 19.1 42.5 42.6 42.5h48.7v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h85v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h48.7c23.5 0 42.6-19.1 42.6-42.5V346.4h-486v395.3zm357.1-600.1l44.9-65c2.6-3.8 2-8.9-1.5-11.4-3.5-2.4-8.5-1.2-11.1 2.6l-46.6 67.6c-30.7-12.1-64.9-18.8-100.8-18.8-35.9 0-70.1 6.7-100.8 18.8l-46.6-67.5c-2.6-3.8-7.6-5.1-11.1-2.6-3.5 2.4-4.1 7.4-1.5 11.4l44.9 65c-71.4 33.2-121.4 96.1-127.8 169.6h486c-6.6-73.6-56.7-136.5-128-169.7zM409.5 244.1a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm208.4 0a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm223.4 100.7c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c.1-30.6-24.3-55.3-54.6-55.3zm-658.6 0c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c0-30.6-24.5-55.3-54.6-55.3z"></path>
                                </svg>
                           </span>
                           <span className="w-32 text-center text-blue-500 trext hover:text-blue-500">Descarcă</span>
                            
                        </a>
                    </div>
                    {
                        /* <div className="mt-3">
                        Apăsați pe{" "}
                        <a
                            href="https://www.youtube.com/watch?v=IB5puTYWUhQ&feature=youtu.be"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-semibold text-blue-500">
                            tutorial
                        </a>{" "}
                        în cazul în care nu vă descurcați să instalați aplicația.
                    </div> */
                    }
                    <div className="py-2">
                        Descarcă resursele adiționale:{" "}
                        <a
                            href="https://dev.smartsense.ro/storage/sunete_pasari.zip"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-semibold text-blue-500">
                            sunete păsări
                        </a>{" "}
                        &{" "}
                        <a
                            href="https://dev.smartsense.ro/storage/Tinte_Vuforia.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-semibold text-blue-500">
                            ținte Vuforia
                        </a>{" "}
                        .
                    </div>
                </div>
                <img className="order-2" src="api.png" alt="Hartă cu puncte de interes / experiențe" />
            </div>
        </div>
    );
};

export default OurApp;
