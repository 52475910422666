import Layout from "./Layout";

const HostedBy = ({ experiencesUser, username, avatar }) => {
    return (
        <Layout title="Găzduit de:">
            <div className="flex flex-row">
                <div className="flex flex-row items-center space-x-4">
                    <img
                        className="object-cover w-12 h-12 rounded-full"
                        src={username ? username?.user_avatar : "/profile/avatar.jpg"}
                        alt=""
                    />
                    <div className="flex flex-col leading-4">
                        {username ? <span className="font-bold">{username?.name}</span> : <span className="font-bold">Necunoscut</span>}
                        {experiencesUser && <span>{experiencesUser.length} experiențe găzduite</span>}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default HostedBy;
